import { memo, useMemo } from "react";
import styles from "./ClassChipSmall.module.css";

const ClassChipSmall = memo(
  ({
    classTitle,
    classSubject,
    getAnswersToAnyQuestionsYBorderRadius,
    propWidth,
    frame30AlignSelf,
    frame30BackgroundColor,
    frame30Width,
    image17,
    checked,
    onClick
  }) => {
    const frameDivStyle = useMemo(() => {
      return {
        borderRadius: getAnswersToAnyQuestionsYBorderRadius,
        alignSelf: frame30AlignSelf,
        backgroundColor: frame30BackgroundColor,
        width: frame30Width,
      };
    }, [
      getAnswersToAnyQuestionsYBorderRadius,
      frame30AlignSelf,
      frame30BackgroundColor,
      frame30Width,
    ]);

    const getAnswersToStyle = useMemo(() => {
      return {
        width: "210px",
      };
    });

    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };

    const containerStyle = {
      ...frameDivStyle,
      cursor: "pointer"
    };

    return (
      <div
        className={styles.frameParent}
        style={containerStyle}
        onClick={handleClick}
      >
        <div className={styles.image17Parent}>
          <img className={styles.image17Icon} alt="" src={image17} />
          <div className={styles.chatWithClassNotesParent}>
            <b className={styles.chatWithClass}>{classTitle}</b>
            <div className={styles.getAnswersTo} style={getAnswersToStyle}>
              {classSubject}
            </div>
          </div>
        </div>
        {checked ? (
          <div className={styles.iconmonstrCheckMark152}>
            <img
              className={styles.vectorIcon}
              alt=""
              src={"/vector.svg"}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default ClassChipSmall;
