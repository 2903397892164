import ResourceHeader from "../components/ResourceHeader";
import styles from "./ClassResources.module.css";
import { useNavigate, Navigate } from "react-router-dom";
import firebase from "firebase/app";
import { useAuth } from "../contexts/FirebaseAuth";
import ResourceChip from "../components/ResourceChip";
import { memo, useState, useRef, useCallback } from "react";
import CreateResourceModal from "../components/CreateResourceModal";
import PortalPopup from "../components/PortalPopup";
import { useSelector, useDispatch } from "react-redux";
import { ago } from "../backend/utility";
// import { Skeleton, Space } from "antd";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "rsuite/dist/rsuite.min.css";
import { Button, Stack } from "rsuite";
import { toast } from "react-toastify";
import "antd/dist/reset.css";

import { linkResource, uploadResource } from "../backend/boilerGPT";
import { externalLinks } from "../backend/externalLinks"; // Adjust the path as needed

import { InboxOutlined, BookOutlined, LinkOutlined } from "@ant-design/icons";

import { message, Space, Upload } from "antd";

import { Divider, Typography } from "antd";

import { Input } from "antd";
import Header from "../components/Header";

const { Title, Paragraph, Text, Link } = Typography;

const { Search } = Input;

const { Dragger } = Upload;

const AddResources = () => {
  const { currentUser } = useAuth();
  const [isCreateResourceModalOpen, setCreateResourceModalOpen] =
    useState(false);
  const selectedClassResources = useSelector(
    (state) => state.selectedClassResources
  );

  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const classData = userClassesData[selectedClassIndex];

  const openCreateResourceModal = useCallback(() => {
    setCreateResourceModalOpen(true);
  }, []);

  const closeCreateResourceModal = useCallback(() => {
    setCreateResourceModalOpen(false);
  }, []);

  const [linking, setLinking] = useState(false);

  const handleLinkResource = async (value) => {
    if (value === "" || !value.includes("http")) {
      const id = toast.error("Link must contain 'https://'", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        closeButton: false,
      });
      return;
    }

    try {
      setLinking(true); // Set linking state to true while awaiting the endpoint response
      console.log("Linking resource:", value);
      await linkResource(classData.classID, value, "", "");
      // getCurrentUserClassResources(classData);
    } catch (error) {
      console.error("Error linking resource:", error);
      // Handle error state or display an error message to the user
    } finally {
      setLinking(false); // Reset linking state after the endpoint response
    }
  };

  const props = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },

    customRequest: async (options) => {
      console.log(options);

      let errorOccurred = false; // Flag to track errors

      try {
        const file = options.file;
        options.onProgress({ percent: 10 });

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 50) {
          console.log("File size exceeds the limit (50MB)");
          options.onError();
          errorOccurred = true; // Set the error flag
          return;
        }

        let progress = 10;
        let interval = setInterval(() => {
          if (errorOccurred) {
            clearInterval(interval); // Stop incrementing progress if an error occurred
            return;
          }

          if (progress < 100) {
            progress += 1;
            options.onProgress({ percent: progress });
          } else {
            clearInterval(interval); // Stop incrementing progress when it reaches 100%
          }
        }, Math.floor((Math.random() * (80000 - 20000) + 20000) / 90.0));

        // const options = {
        //   apiKey: "MY_API_KEY",
        // };

        // const loader = new UnstructuredLoader(
        //   "src/document_loaders/example_data/notion.md",
        //   options
        // );
        // const docs = await loader.load();

        await uploadResource(
          classData.classID,
          file,
          "",
          "",
          () => {
            clearInterval(interval); // Stop incrementing progress on error
            options.onError();
            errorOccurred = true; // Set the error flag
          },
          () => {
            clearInterval(interval); // Stop incrementing progress on success
            options.onSuccess();
          }
        );
      } catch (error) {
        console.error("Error uploading resource:", error);
        options.onError();
        errorOccurred = true; // Set the error flag
      } finally {
        // Handle any cleanup or final actions here
      }
    },
  };

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className={styles.frameParent4}>
        {/* <div className={styles.ultimateParent}>
          <div className={styles.classResourcesParent}>
            <b className={styles.classResources2}>Recently Uploaded</b>
          </div>
          <div className={styles.frameParent5}>
            {selectedClassResources
              .filter(
                (resource) =>
                  resource.status === "Cooking"
              )
              .sort((a, b) => b.upvotes.length - a.upvotes.length)
              .map((data, index) => (
                <ResourceChip
                  key={index}
                  resourceID={data.resourceID}
                  title={data.resourceTitle}
                  uploadedBy={data.creatorName}
                  rawMediaURL={data.rawMediaURL}
                  timeAgo={ago(data.dateModified)}
                  views={data.views}
                  modality={data.modality}
                  upvotes={data.upvotes.length}
                  downvotes={data.downvotes.length}
                  votable={true}
                />
              ))}
          </div>
        </div> */}
        <div className={styles.ultimateParent}>
          <Title>Add Resources</Title>
          <div className={styles.frameParent50}>
            <Dragger
              {...props}
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                width: "300px",
                height: "175px",
              }}
            >
              <div style={{ height: "170px" }}>
                <p className="ant-upload-drag-icon">
                  <BookOutlined />
                </p>
                <p className="ant-upload-text">Upload File</p>
                <p className="ant-upload-hint">
                  Support for a single upload. Uploading copyrighted or
                  proprietary documents are prohibited.
                </p>
              </div>
            </Dragger>

            <Dragger
              {...props}
              folder={true}
              directory={true}
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                width: "300px",
                height: "175px",
              }}
            >
              <div style={{ height: "170px" }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Upload Folder</p>
                <p className="ant-upload-hint">
                  Support for a bulk upload. Uploading copyrighted or
                  proprietary documents are prohibited.
                </p>
              </div>
            </Dragger>

            <div
              style={{
                padding: "20px",
                background: "rgba(243, 243, 243, 1)",
                width: "400px",
                borderRadius: "10px",
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Space direction="vertical" size="middle" align="center">
                <p className="ant-upload-drag-icon">
                  <LinkOutlined
                    style={{ fontSize: "40px", color: "rgba(21, 92, 255, 1)" }}
                  />
                </p>

                <p className="ant-upload-hint">
                  Paste a website link to add it to the class resources. Support
                  for Notion, Google Docs, and any other website.
                </p>
                <Search
                  placeholder="https://notion/so/nt4um49th"
                  enterButton
                  onSearch={handleLinkResource}
                  // value={resourceLink}
                  style={{ width: "340px" }}
                  loading={linking}
                />
              </Space>

              {/* <div className={styles.linkResourceWrapper}>
                <Text strong>Link Resource</Text>
              </div>
              <div className={styles.addResourceModalvariant2Inner1}>
                <div className={styles.resourceTypeParent}>
                  <input
                    className={styles.frameChild}
                    type="text"
                    placeholder="https://notion/so/nt4um49th"
                    value={resourceLink}
                    onChange={handleResourceLinkChange}
                  />
                </div>
              </div>
              <button
                className={styles.linkResourceContainer}
                onClick={handleLinkResource}
                disabled={linking} // Disable the button while linking is in progress
              >
                <b className={styles.linkResource1}>
                  {linking ? "Linking Resource..." : "Link Resource"}
                </b>
              </button> */}
            </div>
          </div>

          {selectedClassResources
            .filter((resource) => resource.status === "In Review")
            .sort((a, b) => b.views - a.views)
            .map((data, index) => (
              <div>
                <Divider />
                <Title>In Review</Title>
                <div className={styles.frameParent5}>
                  <ResourceChip
                    key={index}
                    resourceID={data.resourceID}
                    title={data.resourceTitle}
                    uploadedBy={data.creatorName}
                    rawMediaURL={data.rawMediaURL}
                    timeAgo={ago(data.dateModified)}
                    views={data.views}
                    modality={data.modality}
                    upvotes={data.upvotes.length}
                    downvotes={data.downvotes.length}
                    votable={true}
                    status={data.status}
                    creatorID={data.creatorID}
                  />
                </div>
              </div>
            ))}
          {selectedClassResources.filter(
            (resource) => resource.status === "In Review"
          ).length === 0 && (
            <div className={styles.frameParent5}>
              {/* <Stack wrap spacing={12}>
                {[...Array(8)].map((_, index) => (
                  <p key={index}>
                    <Skeleton
                      width={300}
                      height={110}
                      style={{ borderRadius: "12px" }}
                      baseColor="#EFEFEF"
                      highlightColor="#FAFAFA"
                    />
                  </p>
                ))}
              </Stack> */}
            </div>
          )}

          {/* <Divider />
          <Title>Indexing Error</Title>
          <div className={styles.frameParent5}>
            {selectedClassResources
              .filter((resource) => resource.status === "Indexing Error")
              .sort((a, b) => b.views - a.views)
              .map((data, index) => (
                <ResourceChip
                  key={index}
                  resourceID={data.resourceID}
                  title={data.resourceTitle}
                  uploadedBy={data.creatorName}
                  rawMediaURL={data.rawMediaURL}
                  timeAgo={ago(data.dateModified)}
                  views={data.views}
                  modality={data.modality}
                  upvotes={data.upvotes.length}
                  downvotes={data.downvotes.length}
                  votable={true}
                  status={data.status}
                />
              ))}
            {selectedClassResources.filter(
              (resource) => resource.status === "Indexing Error"
            ).length === 0 && (
              <Stack wrap spacing={12}>
                {[...Array(8)].map((_, index) => (
                  <p key={index}>
                    <Skeleton
                      width={300}
                      height={110}
                      style={{ borderRadius: "12px" }}
                      baseColor="#EFEFEF"
                      highlightColor="#FAFAFA"
                    />
                  </p>
                ))}
              </Stack>
            )}
          </div> */}

{selectedClassResources
            .filter((resource) => resource.status === "Reported")
            .sort((a, b) => b.views - a.views)
            .map((data, index) => (
              <div>
                <Divider />
                <Title>Reported</Title>
                <div className={styles.frameParent5}>
                  <ResourceChip
                    key={index}
                    resourceID={data.resourceID}
                    title={data.resourceTitle}
                    uploadedBy={data.creatorName}
                    rawMediaURL={data.rawMediaURL}
                    timeAgo={ago(data.dateModified)}
                    views={data.views}
                    modality={data.modality}
                    upvotes={data.upvotes.length}
                    downvotes={data.downvotes.length}
                    votable={true}
                    status={data.status}
                    creatorID={data.creatorID}
                  />
                </div>
              </div>
            ))}
          {selectedClassResources.filter(
            (resource) => resource.status === "Reported"
          ).length === 0 && (
            <div className={styles.frameParent5}>
              {/* <Stack wrap spacing={12}>
                {[...Array(8)].map((_, index) => (
                  <p key={index}>
                    <Skeleton
                      width={300}
                      height={110}
                      style={{ borderRadius: "12px" }}
                      baseColor="#EFEFEF"
                      highlightColor="#FAFAFA"
                    />
                  </p>
                ))}
              </Stack> */}
            </div>
          )}

        </div>
        {/* <div className={styles.ultimateParent}>
          <div className={styles.classResourcesParent}>
            <b className={styles.classResources2}>Removed</b>
          </div>
          <div className={styles.frameParent5}>
            {selectedClassResources
              .filter(
                (resource) =>
                  resource.status === "Rejected"
              )
              .sort((a, b) => b.upvotes.length - a.upvotes.length)
              .map((data, index) => (
                <ResourceChip
                  key={index}
                  resourceID={data.resourceID}
                  title={data.resourceTitle}
                  uploadedBy={data.creatorName}
                  rawMediaURL={data.rawMediaURL}
                  timeAgo={ago(data.dateModified)}
                  views={data.views}
                  modality={data.modality}
                  upvotes={data.upvotes.length}
                  downvotes={data.downvotes.length}
                  votable={false}
                />
              ))}
          </div>
        </div> */}
      </div>
      {isCreateResourceModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Centered"
          onOutsideClick={closeCreateResourceModal}
        >
          <CreateResourceModal
            onClose={closeCreateResourceModal}
            openCreateClassModal={openCreateResourceModal}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default AddResources;
