import { memo } from "react";
import styles from "./ResourceChip.module.css";
import type { MenuProps } from "antd";
import {
  DownOutlined,
  SmileOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  viewResource,
  upvoteResource,
  downvoteResource,
  approveResource,
  rejectResource,
  deleteResource,
  reIndexResource,
  reportResource,
} from "../backend/boilerGPT";

import { Dropdown, Space } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../contexts/FirebaseAuth";

import Youtube from "../applicationIcons/modalityIcons/YouTube.png";
import Notion from "../applicationIcons/modalityIcons/Notion.png";
import GoogleDocs from "../applicationIcons/modalityIcons/GoogleDocs.png";
import PDF from "../applicationIcons/modalityIcons/PDF.png";
import Website from "../applicationIcons/modalityIcons/Website.png";
import Reddit from "../applicationIcons/modalityIcons/Reddit.png";
import File from "../applicationIcons/modalityIcons/File.png";
import Image from "../applicationIcons/modalityIcons/Image.png";
import HTML from "../applicationIcons/modalityIcons/HTML.png";

const ResourceChip = memo(
  ({
    title,
    uploadedBy,
    timeAgo,
    rawMediaURL,
    views,
    modality,
    resourceID,
    upvotes,
    votable,
    status,
    creatorID
  }) => {
    const { currentUser } = useAuth();
    const userClassesData = useSelector((state) => state.userClassesData);
    const selectedClassIndex = useSelector((state) => state.selectedClassIndex);

    const isModerator =
      currentUser &&
      userClassesData[selectedClassIndex] &&
      userClassesData[selectedClassIndex].moderators.includes(currentUser.uid);
    // console.log("isModerator", isModerator);

    const isResourceCreator = currentUser && creatorID == currentUser.uid;
    // console.log("isResourceCreator", isResourceCreator);

    if (modality === "application/website/youtube") {
      var iconSrc = Youtube;
    } else if (modality === "application/website/notion") {
      var iconSrc = Notion;
    } else if (modality === "GoogleDocs") {
      var iconSrc = GoogleDocs;
    } else if (modality === "application/pdf") {
      var iconSrc = PDF;
    } else if (modality === "application/website/reddit") {
      var iconSrc = Reddit;
    } else if (modality === "application/website") {
      var iconSrc = Website;
    } else if (modality === "File") {
      var iconSrc = File;
      //otherwise check if the word "image" is in the modality string
    } else if (modality && modality.includes("image")) {
      var iconSrc = Image;
    } else if (modality === "text/html") {
      var iconSrc = HTML;
    } else {
      var iconSrc = File;
    }

    const handleUpvote = async () => {
      upvoteResource(resourceID);
    };

    const handleDownvote = async () => {
      downvoteResource(resourceID);
    };

    const handleApprove = async () => {
      approveResource(resourceID);
    };

    const handleReject = async () => {
      rejectResource(resourceID);
    };

    const handleReport = async () => {
      reportResource(resourceID);
    };

    const handleRemove = async () => {
      deleteResource(resourceID);
    };

    const handleReIndex = async () => {
      reIndexResource(resourceID);
    };

    const onClick: MenuProps["onClick"] = ({ key }) => {
      console.log(`Click on item ${key}`);
      if (key === "1") {
        window.open(rawMediaURL, "_blank");
        viewResource(resourceID);
      } else if (key === "2") {
        handleUpvote();
      } else if (key === "3") {
        handleDownvote();
      } else if (key === "6") {
        handleReport();
      } else if (
        key === "4" &&
        isModerator &&
        status === "In Review"
      ) {
        handleApprove();
      } else if (
        key === "5" &&
        isModerator &&
        status === "In Review"
      ) {
        handleReject();
      } else if (
        key === "4" &&
        ((isModerator && (status === "Accepted" || status === "Indexing Error" || status === "Reported")) ||
          (isResourceCreator && (status === "Accepted" || status === "Indexing Error" || status === "Reported")))
      ) {
        handleRemove();
      } else if (
        key === "5" &&
        ((isModerator && (status === "Accepted" || status === "Indexing Error" || status === "Reported")) ||
          (isResourceCreator && (status === "Accepted" || status === "Indexing Error" || status === "Reported")))
      ) {
        handleReIndex();
      }
    };
    
    let items: MenuProps["items"] = [
      {
        key: "1",
        icon: <EyeOutlined />,
        label: "View Resource",
      },
      {
        key: "2",
        icon: <ArrowUpOutlined />,
        label: "Mark Useful",
      },
      {
        key: "3",
        icon: <ArrowDownOutlined />,
        label: "Mark Not Useful",
      },
      {
        key: "6",
        danger: true,
        icon: <ExclamationCircleOutlined />,
        label: "Report Resource",
      },
    ];
    
    if (status === "In Review") {
      if (isModerator) {
        items.splice(3, 0, {
          key: "4",
          icon: <UserAddOutlined />,
          label: "Approve Resource",
        });
        items.splice(4, 0, {
          key: "5",
          icon: <UserDeleteOutlined />,
          label: "Decline Resource",
          danger: true,
        });
      }
    } else if (status === "Accepted" || status === "Indexing Error" || status === "Reported") {
      if (isModerator || isResourceCreator) {
        items.splice(4, 0, {
          key: "4",
          icon: <DeleteOutlined />,
          label: "Remove Resource",
          danger: true,
        });
        items.splice(3, 0, {
          key: "5",
          icon: <ReloadOutlined />,
          label: "Re-Index Resource",
        });
      }
    }

    return (
      <button className={styles.resourceChip}>
        <div className={styles.resourceChipInner}>
          <div className={styles.freshmanYear20212023Parent}>
            <b
              className={styles.freshmanYear20212023}
              onClick={() => {
                window.open(rawMediaURL, "_blank");
                viewResource(resourceID);
              }}
            >
              {title}
            </b>
            <Dropdown menu={{ items, onClick }}>
              <a onClick={(e) => e.preventDefault()}>
                <div className={styles.ellipsis1Wrapper}>
                  <img
                    className={styles.ellipsis1Icon}
                    alt=""
                    src="/ellipsis-11.svg"
                  />
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
        <div
          className={styles.resourceChipChild}
          onClick={() => {
            //make it open the file inline if it's an image
            window.open(rawMediaURL, "_blank");
            viewResource(resourceID);
          }}
        >
      
          <div className={styles.image9Parent}>
            <img className={styles.image9Icon} alt="" src={iconSrc} />
            <div className={styles.pages23DaysAgoParent}>
              <b className={styles.pages23}>
                {views === 0 ? timeAgo : String(views) + " Views • " + timeAgo}
              </b>

              <div className={styles.uploadedByJames}>
                Uploaded by {uploadedBy}
              </div>
            </div>
          </div>
        </div>
      </button>
    );
  }
);

export default ResourceChip;
