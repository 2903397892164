import { memo } from "react";
import styles from "./SidebarButton.module.css";
import { useNavigate } from "react-router-dom";

const SidebarButton = memo(
  ({ iconPath, buttonText, slug, selected, setSelected }) => {
    const navigate = useNavigate();

    async function handleCLick(event) {
      event.preventDefault();

      setSelected(slug);

      try {
        navigate(slug);
      } catch {}
    }

    return (
      <button
        className={selected ? styles.brain1ParentSelected : styles.brain1Parent}
        onClick={handleCLick}
      >
        <img className={styles.brain1Icon} alt="" src={iconPath} />
        <b className={styles.learnConcepts}>{buttonText}</b>
      </button>
    );
  }
);

export default SidebarButton;
