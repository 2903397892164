import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function Resource() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const resourceID = urlParams.get("resourceID");

  const fileStorage = "https://boilergptfiles.blob.core.windows.net/user-uploads/"

  const [localResourceUrl, setLocalResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Download the file as a temporary blob
      //add cors to the request
      const resourceBlob = await fetch(fileStorage + resourceID, {
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
        }
        }).then((response) => response.blob());



      // Create a file from the blob
      const resourceFile = new File([resourceBlob], "resource.pdf", {
        type: "application/pdf",
      });

      // Create a file URL from the file
      const url = URL.createObjectURL(resourceFile);

      setLocalResourceUrl(url);
    };

    fetchData();
  }, [resourceID]);

  if (!localResourceUrl) {
    return <div>Loading...</div>;
  }

  const docs = [{ uri: localResourceUrl }];

  return (
    <DocViewer
      prefetchMethod="GET"
      pluginRenderers={DocViewerRenderers}
      documents={docs}
      config={{
        header: {
          disableHeader: false,
          disableFileName: false,
          retainURLParams: true,
        },
      }}
    />
  );
}

export default Resource;
