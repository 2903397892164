import ResourceHeader from "../components/ResourceHeader";
import styles from "./ClassResources.module.css";
import { useNavigate, Navigate } from "react-router-dom";
import firebase from "firebase/app";
import { useAuth } from "../contexts/FirebaseAuth";

const PracticeProblems = () => {
  const { currentUser } = useAuth();

  if(!currentUser){
    return <Navigate to="/" />
  }
  return (
      <iframe
        src="https://www.boilerexams.com/study"
        title="Practice Problems"
        width="100%"
        height="600px"
        style={{ border: "none", minHeight: "calc(100vh - 54px)", maxHeight: "calc(100vh - 54px)" }}
      ></iframe>
  );
};

export default PracticeProblems;

