import React from "react";
import styles from "./codeCopyBtn.module.css";


export default function CodeCopyBtn({ children }) {
    const [copyOk, setCopyOk] = React.useState(false);
    const iconColor = copyOk ? '#0af20a' : '#ddd';
    const icon = copyOk ? 'fa-check-square' : 'fa-copy';
    const handleClick = (e) => {
        navigator.clipboard.writeText(children[0].props.children[0]);
        console.log(children)
        setCopyOk(true);
        setTimeout(() => {
            setCopyOk(false);
        }, 500);
    }


    return (
        //div with button pushed to the far right

        <div style={{ position: 'relative',  display: 'flex', justifyContent: 'right', alignItems: 'right', cursor: 'pointer', backgroundColor: 'transparent' }}>
        <button
              className={styles.questionmarkdiamond1}
            //   ref={questionmarkdiamond1Ref}
              onClick={handleClick}
              style={{ position: 'absolute', right: '15px', top: '20px' }}
            >
              <img
                className={styles.questionmarkapp2Icon}
                alt=""
                src="/clipboard.svg"
              />
            </button>
        </div>
    )
}


// .code-copy-btn {
//     color: white;
//     position: absolute;
//     right: 10px;
//     top: -2px;
//     font-size: 1.5em;
//     cursor: pointer;
//     transition: all 0.3s ease-in-out;
//   }
//   .code-copy-btn:hover {
//     transform: scale(1.1);
//     opacity: 0.9;
//   }