import { memo } from "react";
import UserProfile from "./UserProfile";
import styles from "./MyAccountModal.module.css";
import { useNavigate } from 'react-router-dom'
import app from "../firebase"
import StandardButton from "./StandardButton";
import { useSelector } from 'react-redux';
import { convertTimestamp } from "../backend/utility";
import { useAuth } from "../contexts/FirebaseAuth"
import { exportUserData } from "../backend/boilerGPT";

const MyAccountModal = memo(({ onClose }) => {

  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const userData = useSelector((state) => state.userData);

  if(!currentUser || userData == []) {
    return <></>
  }

  async function handleLogout() {
    app.auth().signOut()
    navigate("/")
    console.log("LOGOUT SUCCESS")
}

async function handleExportData() {
  console.log("EXPORT DATA")
  var response = await exportUserData()

  // Create a blob from the response data
  const blob = new Blob([response.data], { type: 'application/json' });

  // Create a URL object from the blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary <a> element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = 'user_data.json';
  link.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
}

  return (
    <div className={styles.myAccountModal}>
      <div className={styles.myAccountModalvariant3}>
        <div className={styles.myAccountWrapper}>
          <b className={styles.myAccount}>MY ACCOUNT</b>
        </div>
        <UserProfile
          userName={userData.firstName + " " + userData.lastName}
          dateCreated={"Since " + convertTimestamp(userData.dateJoined)}
          profilePictureURL={userData.profilePictureURL}
        />
        <div className={styles.myAccountModalvariant3Inner}>
          <img className={styles.frameChild} alt="" src="/line-31.svg" />
        </div>
        <div className={styles.myAccountWrapper}>
          <b className={styles.myAccount}>MY STATISTICS</b>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.chatsWrapper}>
            <b className={styles.myAccount}>{parseInt(userData.resourceIDsLinked.length + userData.resourceIDsUploaded.length) + " Resources Uploaded"}</b>
          </div>
          <div className={styles.chatsWrapper}>
            <b className={styles.myAccount}>{parseInt(userData.classesModerating.length + userData.classesStudent.length + userData.classesTeaching.length) + " Classes Joined"}</b>
          </div>
        </div>
        <div className={styles.myAccountModalvariant3Inner}>
          <img className={styles.frameChild} alt="" src="/line-31.svg" />
        </div>
        {/* <StandardButton
          buttonText="Export My Data"
          backgroundColor="#efefef"
          buttonWidth="282px"
          onClick={handleExportData}
        /> */}
        <StandardButton
          buttonText="Sign Out"
          backgroundColor="#e43e52"
          buttonWidth="282px"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
});

export default MyAccountModal;
