import React, { useState } from "react";
import { memo } from "react";
import styles from "./CreateResourceModal.module.css";
import { linkResource, uploadResource } from "../backend/boilerGPT";
import { useSelector } from "react-redux";
import { externalLinks } from "../backend/externalLinks"; // Adjust the path as needed
import { setSelectedClassResources } from "../redux/actions";
import { getCurrentUserClassResources } from "../backend/subscriptions";

const CreateResourceModal = memo(({ onClose }) => {
  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const classData = userClassesData[selectedClassIndex];

  const [resourceType, setResourceType] = useState("classSpecific");
  const [resourceTitle, setResourceTitle] = useState("");
  const [resourceLink, setResourceLink] = useState("");
  const [uploading, setUploading] = useState(false);
  const [linking, setLinking] = useState(false);

  const handleResourceTypeChange = (event) => {
    setResourceType(event.target.value);
  };

  const handleResourceTitleChange = (event) => {
    setResourceTitle(event.target.value);
  };

  const handleResourceLinkChange = (event) => {
    setResourceLink(event.target.value);
  };

  const handleLinkResource = async () => {
    try {
      setLinking(true); // Set linking state to true while awaiting the endpoint response
      console.log("Linking resource:", resourceLink);
      await linkResource(
        classData.classID,
        resourceLink,
        resourceType,
        resourceTitle
      );
      onClose();
      getCurrentUserClassResources(classData);
    } catch (error) {
      console.error("Error linking resource:", error);
      // Handle error state or display an error message to the user
    } finally {
      setLinking(false); // Reset linking state after the endpoint response
    }
  };

  const handleUploadResource = async () => {
    try {
      setUploading(true); // Set uploading state to true while awaiting the endpoint response
      console.log("Uploading resource:", resourceTitle);

      const fileInput = document.getElementById("resourceFile");
      const file = fileInput.files[0];

      // Validate the file size (less than 50MB)
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 50) {
        console.log("File size exceeds the limit (50MB)");
        return;
      }

      // Call the uploadResource function to upload the file
      await uploadResource(
        classData.classID,
        file,
        resourceType,
        resourceTitle
      );

      getCurrentUserClassResources(classData);
      onClose();
    } catch (error) {
      console.error("Error uploading resource:", error);
      // Handle error state or display an error message to the user
    } finally {
      setUploading(false); // Reset uploading state after the endpoint response
    }
  };

  return (
    <div className={styles.createResourceModal}>
      <div className={styles.addResourceModalvariant2}>
        <div className={styles.linkResourceWrapper}>
          <b className={styles.linkResource}>ADD RESOURCES</b>
        </div>
        <div className={styles.addResourceModalvariant2Inner}>
          <div className={styles.resourceTypeParent}>
            <div className={styles.resourceType}>Resource Type</div>
            <select
              className={styles.classSpecificParent}
              value={resourceType}
              onChange={handleResourceTypeChange}
            >
              <option value="classSpecific">Class Specific Resource</option>
              <option value="areaOfStudy">Topic Resource</option>
            </select>
          </div>
        </div>
        <div className={styles.addResourceModalvariant2Child}>
          <div className={styles.resourceTypeParent}>
            <div className={styles.resourceType}>Resource Title</div>
            <input
              className={styles.frameChild}
              type="text"
              placeholder="Mitocondria Lecture Notes Week 5"
              value={resourceTitle}
              onChange={handleResourceTitleChange}
            />
          </div>
        </div>
        <div className={styles.frameDiv}>
          <img className={styles.frameItem} alt="" src="/line-3.svg" />
        </div>
        <div className={styles.linkResourceWrapper}>
          <b className={styles.linkResource}>LINK RESOURCE</b>
        </div>
        <div className={styles.howToLinkAResourceParent}>
          <b className={styles.resourceType}>How to Link a Resource</b>
          <div className={styles.pasteAPublicContainer}>
            <span>
              Paste a public link into the text box below. Make sure to enable
              public mode for any Notion page, Google Doc, or other resource.
            </span>
            <a href={externalLinks.linkResources} target="_blank">
              <b> Learn more.</b>
            </a>
            <span> </span>
          </div>
        </div>
        <div className={styles.addResourceModalvariant2Inner1}>
          <div className={styles.resourceTypeParent}>
            <div className={styles.resourceType}>
              Resource Link (Notion, YouTube, Google Doc)
            </div>
            <input
              className={styles.frameChild}
              type="text"
              placeholder="https://notion/so/nt4um49th"
              value={resourceLink}
              onChange={handleResourceLinkChange}
            />
          </div>
        </div>
        <button
          className={styles.linkResourceContainer}
          onClick={handleLinkResource}
          disabled={linking} // Disable the button while linking is in progress
        >
          <b className={styles.linkResource1}>
            {linking ? "Linking Resource..." : "Link Resource"}
          </b>
        </button>
        <div className={styles.frameDiv}>
          <img className={styles.frameItem} alt="" src="/line-3.svg" />
        </div>
        <div className={styles.linkResourceWrapper}>
          <b className={styles.linkResource}>UPLOAD RESOURCE</b>
        </div>
        <div className={styles.howToLinkAResourceParent}>
          <b className={styles.resourceType}>How to Upload a Resource</b>
          <div className={styles.pasteAPublicContainer}>
            <span>
              Drag and drop any file into the box below. Make sure it is less
              than 50 MB.
            </span>
            <a href={externalLinks.uploadResources} target="_blank">
              <b> Learn more.</b>
            </a>
            <span> </span>
          </div>
        </div>
        <div className={styles.classChipWrapper}>
          <input
            className={styles.classChip}
            type="file"
            id="resourceFile" // Add the ID attribute here
            webkitdirectory
            directory
          />
        </div>
        <button
          className={styles.linkResourceContainer}
          onClick={handleUploadResource}
          disabled={uploading} // Disable the button while uploading is in progress
        >
          <b className={styles.linkResource1}>
            {uploading ? "Uploading Resource..." : "Upload Resource"}
          </b>
        </button>
      </div>
    </div>
  );
});

export default CreateResourceModal;
