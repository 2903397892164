import React from "react";
import styles from "../../pages/LearnConcepts.module.css"; // Assuming you have a CSS module file for styling
import { useSelector } from "react-redux";
import Markdown from "./Markdown.tsx";

const UserMessage = ({ text }) => {
  const userData = useSelector((state) => state.userData);

  const userProfileFallback = require("../../../src/applicationIcons/userProfileFallback.png");

  const handleImageError = (event) => {
    event.target.src = userProfileFallback;
  };

  return (
    <div className={styles.frameWrapper}>
      <div className={styles.frameWrapper1}>
        <div className={styles.frameParent6}>
          <div className={styles.image15Parent}>
            <img
              className={styles.image15}
              alt=""
              src={userData.profilePictureURL}
              onError={handleImageError}
              referrerPolicy="no-referrer"
            />
            <div className={styles.jackBlair}>
            {userData.firstName ? userData.firstName + " " + userData.lastName : ""}
            </div>
          </div>
          <div className={styles.onceUponAContainer}>
          <Markdown className={styles.onceUponA} text={text} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
