import { memo } from "react";
import styles from "./UserProfile.module.css";
const UserProfile = memo(
  ({ userName, dateCreated, profilePictureURL }) => {
    const userProfileFallback = require("../../src/applicationIcons/userProfileFallback.png");

    const handleImageError = (event) => {
      event.target.src = userProfileFallback;
      };

    return (
      <div className={styles.myAccountModalvariant3Inner}>
        <div className={styles.image15Parent}>
          <img className={styles.image15Icon} alt="" src={profilePictureURL} onError={handleImageError} referrerPolicy="no-referrer"/>
          <div className={styles.sarahJohnsonParent}>
            <b className={styles.sarahJohnson}>{userName}</b>
            <div className={styles.sinceMarch23}>{dateCreated}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default UserProfile;
