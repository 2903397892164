// this file will contain the Axios setup link between API and Front-end
import boilerGPTApp from "../AxiosSetup.js";
// import firebase from "firebase/app";
// import "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { toast } from "react-toastify";

// export const BACKEND_URL = "http://localhost:10000/";
export const BACKEND_URL = "https://backend-boilergpt-prod-eastus-001.azurewebsites.net/";
// export const BOILERCHAIN_URL = "http://localhost:6001/";
export const BOILERCHAIN_URL = "https://boilerchain-boilergpt-prod-eastus-001.azurewebsites.net/";

// POST request functions

export const createUser = async (userID, firstName, lastName, email) => {
  const id = toast.loading("Creating New User", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/createUser",
      {
        userID: userID,
        firstName: firstName,
        lastName: lastName,
        email: email,
      },
      {
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "User Successfully Created",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Creating User | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Creating User | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const createClass = async (
  term,
  classNumber,
  classTitle,
  schoolName,
  schoolCode
) => {
  const id = toast.loading("Creating New Class", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/createClass",
      {
        term: term,
        classNumber: classNumber,
        classTitle: classTitle,
        schoolName: schoolName,
        schoolCode: schoolCode,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Class Successfully Created and Joined",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Creating Class | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Creating Class | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const joinClass = async (classID) => {
  const id = toast.loading("Joining Class", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/joinClass",
      {
        classID: classID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Class Successfully Joined",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Joining Class | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Joining Class | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const leaveClass = async (classID) => {
  const id = toast.loading("Leaving Class", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/leaveClass",
      {
        classID: classID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Class Successfully Left",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Leaving Class | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Leaving Class | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const requestModerator = async (classID) => {
  const id = toast.loading("Requesting Moderator", {
    position: "bottom-right",
  });

  return boilerGPTApp
    .post(
      "/requestModerator",
      {
        classID: classID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Moderator Successfully Requested",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Requesting Moderator | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Requesting Moderator | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const assignModerator = async (classID, moderatorID) => {
  const id = toast.loading("Assigning Moderator", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/assignModerator",
      {
        classID: classID,
        moderatorID: moderatorID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Moderator Successfully Assigned",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Assigning Moderator | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Assigning Moderator | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const deleteClass = async (classID) => {
  const id = toast.loading("Deleting Class", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/deleteClass",
      {
        classID: classID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Class Successfully Deleted",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Deleting Class | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Deleting Class | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const deleteChat = async (chatID) => {
  const id = toast.loading("Deleting Chat", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/deleteChat",
      {
        chatID: chatID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Chat Successfully Deleted",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Deleting Chat | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Deleting Chat | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const updateChat = async (chatID, messages) => {
  return boilerGPTApp.post(
    "/updateChat",
    {
      chatID: chatID,
      messages: messages,
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
      baseURL: BACKEND_URL,
    }
  );
};

export const searchClasses = async (searchString) => {
  return boilerGPTApp.post(
    "/searchClasses",
    {
      searchString: searchString,
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
      baseURL: BACKEND_URL,
    }
  );
};

export const getClass = async (classID) => {
  const id = toast.loading("Loading Class", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/getClass",
      {
        classID: classID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Class Successfully Loaded",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Loading Class | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Loading Class | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const getResource = async (resourceID) => {
  return boilerGPTApp.post(
    "/getResource",
    {
      resourceID: resourceID,
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
      baseURL: BACKEND_URL,
    }
  );
};

export const viewResource = async (resourceID) => {
  return boilerGPTApp.post(
    "/viewResource",
    {
      resourceID: resourceID,
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
      baseURL: BACKEND_URL,
    }
  );
};

export const upvoteResource = async (resourceID) => {
  const id = toast.loading("Upvoting Resource", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/upvoteResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Resource Successfully Upvoted",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Upvoting Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Upvoting Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const downvoteResource = async (resourceID) => {
  const id = toast.loading("Downvoting Resource", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/downvoteResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Resource Successfully Downvoted",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Downvoting Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Downvoting Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const approveResource = async (resourceID) => {
  const id = toast.loading("Approving Resource", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/approveResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Resource Successfully Approved",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Approving Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Approving Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const rejectResource = async (resourceID) => {
  const id = toast.loading("Rejecting Resource", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/declineResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Resource Successfully Rejected",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Rejecting Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Rejecting Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const deleteResource = async (resourceID) => {
  const id = toast.loading("Deleting Resource", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/deleteResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Resource Successfully Deleted",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Deleting Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Deleting Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const getUser = async (userID) => {
  return boilerGPTApp.post(
    "/getUser",
    {
      userID: userID,
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
      baseURL: BACKEND_URL,
    }
  );
};

export const linkResource = async (
  classID,
  rawMediaURL,
  resourceType,
  resourceTitle
) => {
  const id = toast.loading("Submitting Linked Resource", {
    position: "bottom-right",
  });

  return boilerGPTApp
    .post(
      "/linkResource",
      {
        classID: classID,
        rawMediaURL: rawMediaURL,
        resourceType: resourceType,
        resourceTitle: resourceTitle,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success) {
        toast.update(id, {
          render:
            "Resource successfully linked. It will be in the Add Resources tab until approved by a moderator.",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      } else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Linking Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Linking Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const uploadResource = async (
  classID,
  file,
  resourceType,
  resourceTitle,
  onError,
  onSuccess
) => {
  const id = toast.loading(
    "Submitting Uploaded Resource (For very large files, this may take a few minutes. Please keep this window open.)",
    { position: "bottom-right" }
  );

  const formData = new FormData();
  formData.append("classID", classID);
  formData.append("resourceType", resourceType);
  formData.append("file", file);
  formData.append("resourceTitle", resourceTitle);

  return boilerGPTApp
    .post("/uploadResource", formData, {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
        "Content-Type": "multipart/form-data",
      },
      baseURL: BACKEND_URL,
    })
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success){
        toast.update(id, {
          render:
            "Resource successfully uploaded. It will be in the Add Resources tab until approved by a moderator.",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
        onSuccess();
      }
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Uploading Resource | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
        onError();
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Uploading Resource | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
      onError();
    });
};

export const uploadMicrosoftPFP = async (imageFile) => {
  const id = toast.loading("Uploading Profile Photo", {
    position: "bottom-right",
  });

  const formData = new FormData();
  formData.append("image", imageFile);

  return boilerGPTApp
    .post("/uploadMicrosoftPFP", formData, {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
        "Content-Type": "multipart/form-data",
      },
      baseURL: BACKEND_URL,
    })
    .then((res) => {
      if (res.data.success) {
        toast.update(id, {
          render: "Profile photo uploaded successfully.",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      } else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error uploading profile photo | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error uploading profile photo | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const createChat = async (classID, messages) => {
  return boilerGPTApp.post(
    "/createChat",
    {
      classID: classID,
      messages: messages,
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
      baseURL: BACKEND_URL,
    }
  );
};

export const exportUserData = async () => {
  const id = toast.loading("Exporting User Data", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/exportUserData",
      {},
      {
        responseType: "blob",
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .finally(() => {
      toast.update(id, {
        render: "User Data Successfully Exported",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
    })
    .catch((err) => {
      //show the error in the response
      toast.update(id, {
        render: "Error Exporting User Data",
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const acceptTOS = async () => {
  const id = toast.loading("Accepting Terms of Service", {
    position: "bottom-right",
  });

  return boilerGPTApp
    .post(
      "/acceptTOS",
      {},
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .then((res) => {
      //if success in respnce json is true, then show success toast
      if (res.data.success)
        toast.update(id, {
          render: "Terms of Service Successfully Accepted",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error Accepting Terms of Service | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      //show the error in the response
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error Accepting Terms of Service | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const setGraduationYear = async (graduationYear) => {
  const id = toast.loading("Setting Graduation Year", {
    position: "bottom-right",
  });

  const requestData = {
    graduationYear: graduationYear,
  };

  return boilerGPTApp
    .post("/setGraduationYear", requestData, {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
        "Content-Type": "application/json",
      },
      baseURL: BACKEND_URL,
    })
    .then((res) => {
      if (res.data.success) {
        toast.update(id, {
          render: "Graduation year updated successfully.",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
      } else {
        console.log(res.data.error);
        toast.update(id, {
          render: "Error updating graduation year | " + res.data.error,
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.error);
      toast.update(id, {
        render: "Error updating graduation year | " + err.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const reIndexResource = async (resourceID) => {
  const id = toast.loading("Reindexing Resources", {
    position: "bottom-right",
  });

  return boilerGPTApp
    .post(
      "/reIndexResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .finally(() => {
      toast.update(id, {
        render: "Resource Successfully Reindexed",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
    })
    .catch((err) => {
      //show the error in the response
      toast.update(id, {
        render: "Error Reindexing Resource",
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};

export const reportResource = async (resourceID) => {
  const id = toast.loading("Reporting Resource", { position: "bottom-right" });

  return boilerGPTApp
    .post(
      "/reportResource",
      {
        resourceID: resourceID,
      },
      {
        headers: {
          Authorization: await firebase.auth().currentUser.getIdToken(),
        },
        baseURL: BACKEND_URL,
      }
    )
    .finally(() => {
      toast.update(id, {
        render: "Resource Successfully Reported",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
    })
    .catch((err) => {
      //show the error in the response
      toast.update(id, {
        render: "Error Reporting Resource",
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    });
};
