import styles from "./OnboardingScreen.module.css";
import { useNavigate, Navigate } from "react-router-dom";
import firebase from "firebase/app";
import PortalPopup from "../components/PortalPopup";
import { useAuth } from "../contexts/FirebaseAuth";
import { memo, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import JoinClassModal from "../components/JoinClassModal";
import CreateClassModal from "../components/CreateClassModal";
import ReactPlayer from "react-player";
import { acceptTOS } from "../backend/boilerGPT";
import { toast } from "react-toastify";
import { Space, Button, Checkbox, Select } from "antd"; // Import Select from Ant Design
import { setGraduationYear } from "../backend/boilerGPT";
import { externalLinks } from "../backend/externalLinks";

const { Option } = Select; // Destructure Option from Select

const OnboardingScreen = () => {
  const { currentUser } = useAuth();

  const [onboardingSection, setOnboardingSection] = useState(1);
  const [isCreateClassModalOpen, setCreateClassModalOpen] = useState(false);
  const [graduationYear, selectGraduationYear] = useState("");

  const selectedClassResources = useSelector(
    (state) => state.selectedClassResources
  );

  const incrementOnboardingSection = () => {
    let newSection = onboardingSection + 1;

    console.log("New onboarding section: " + onboardingSection);
    if (newSection > 3) {
      //navigate to /dashboard/learn

      //if the tos checkbox is not checked, lets make a toastify
      if (!document.getElementById("tosCheckbox").checked) {
        console.log("TOS not checked");
        toast.error("Please accept the terms of service to continue", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      console.log("Navigating to /dashboard/learn");
      setOnboardingSection(onboardingSection + 1);
      return <Navigate to="/dashboard/learn" />;
    }

    setOnboardingSection(onboardingSection + 1);
  };

  const onAcceptTOS = async () => {
    console.log("Accepted TOS");
    var response = await acceptTOS();
    console.log(response);

    //disable the checkbox
    document.getElementById("tosCheckbox").disabled = true;
  };

  const onSelectGraduationYear = async (value) => {
    console.log(value);
    selectGraduationYear(value); // Update the state with the selected graduation year

    var response = await setGraduationYear(value);
    console.log(response);
  };

  const openCreateClassModal = useCallback(() => {
    console.log("open create class modal");
    setCreateClassModalOpen(true);
  }, []);

  const closeCreateClassModal = useCallback(() => {
    setCreateClassModalOpen(false);
  }, []);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  if (onboardingSection > 3) {
    const rootURL = window.location.href.split("?")[0];
    console.log(rootURL);
    //remove any slugs from the root URL
    const rootURLNoSlugs = rootURL.split("/").slice(0, 3).join("/");
    console.log(rootURLNoSlugs);

    const link = rootURLNoSlugs + "/dashboard/learn";

    window.open(link);
    window.close();
  }

  return (
    <div className={styles.onboardingScreen}>
      <div className={styles.onboardingScreen1}>
        {onboardingSection == 1 ? (
          <b className={styles.title}>Welcome to BoilerGPT</b>
        ) : null}
        {onboardingSection == 2 ? (
          <b className={styles.title}>Join Your Classes</b>
        ) : null}
        {onboardingSection == 3 ? (
          <b className={styles.title}>Academic Integrity</b>
        ) : null}
        <div className={styles.rectangleParent}>
          <div
            className={
              onboardingSection == 1 ? styles.frameInner : styles.frameChild
            }
          />
          <div
            className={
              onboardingSection == 2 ? styles.frameInner : styles.frameChild
            }
          />
          <div
            className={
              onboardingSection == 3 ? styles.frameInner : styles.frameChild
            }
          />
        </div>

        {onboardingSection == 3 ? (
          <div className={styles.onboardingScreen1}>
            <div className={styles.boilergptIsNot}>
              {`BoilerGPT is not a cheating platform. Our goal is to help as many students as possible learn more efficiently. `}
            </div>
            <i className={styles.asABoilermaker}>
              As a Boilermaker pursuing academic excellence, I pledge to be
              honest and true in all that I do. Accountable together - We are
              Purdue.
            </i>

            <Space size="medium" direction="horizontal">
              <span style={{ marginRight: "10px" }}>Your Graduation Year</span>
              <br />
              <Select
                style={{ width: 80 }}
                onChange={onSelectGraduationYear}
                value={graduationYear}
              >
                <Option value="2023">2023</Option>
                <Option value="2024">2024</Option>
                <Option value="2025">2025</Option>
                <Option value="2026">2026</Option>
                <Option value="2027">2027</Option>
              </Select>
            </Space>

            <div className={styles.iUnderstandThat}>
              <input
                type="checkbox"
                id="tosCheckbox"
                name="tosCheckbox"
                value="tosCheckbox"
                onClick={onAcceptTOS}
              />
              <label for="tosCheckbox">
                I understand that BoilerGPT is intended to be used as a resource
                sharing platform and will not tolerate cheating in any way.
                Comments or specific questions regarding current exams should
                not be shared on this platform. Textbooks (or sections of) that
                require purchase for download or print are not to be uploaded to
                resources. By clicking the box you agree to the{" "}
                <a href={externalLinks.termsOfService} target="_blank">
                  BoilerGPT Terms of Service
                </a>
                .
              </label>
          s</div>
          </div>
        ) : null}
        {onboardingSection == 1 ? (
          <ReactPlayer
            url={externalLinks.introYoutube}
            playing={true}
            width={947}
            height={533}
          />
        ) : null}
        {onboardingSection == 2 ? (
          <div>
            <div className={styles.boilergptIsNot}>
              {`Take the time to join any classes on your schedule. If you are an early adopter your classes may not be listed, in which case please create the class for your fellow students.   `}
            </div>
            <div className={styles.boilergptIsNotNot}>
              <JoinClassModal
                onClose={() => {}}
                onboarding={true}
                openCreateClassModal={openCreateClassModal}
              />
            </div>
          </div>
        ) : null}

        <button
          className={styles.googleContinue}
          onClick={incrementOnboardingSection}
        >
          {onboardingSection == 3 ? (
            <b className={styles.launchBoilergpt}>Launch BoilerGPT</b>
          ) : (
            <b className={styles.launchBoilergpt}>Continue</b>
          )}
        </button>
      </div>

      {isCreateClassModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Centered"
          onOutsideClick={closeCreateClassModal}
        >
          <CreateClassModal onClose={closeCreateClassModal} />
        </PortalPopup>
      )}
    </div>
  );
};

export default OnboardingScreen;
