import React from "react";
import styles from "./LLMMessage.module.css"; // Assuming you have a CSS module file for styling
import Markdown from "./Markdown.tsx";
import { Skeleton } from 'antd';

const LLMMessage = ({ text, aiName, iconSrc }) => {
  return (
    <div className={styles.frameWrapper2}>
      <div className={styles.frameParent7}>
        <div className={styles.frameParent6}>
          <div className={styles.image15Parent}>
            <img className={styles.allergensfill1Icon} alt="" src={iconSrc} />
            <div className={styles.jackBlair}>{aiName}</div>
          </div>
          <div className={styles.onceUponAContainer1}>
            {text === "" ? (
              <Skeleton active />
            ) : (
            <Markdown className={styles.onceUponA} text={text} />
            )}
          </div>
        </div>
        <div className={styles.resourceChipParent}></div>
      </div>
    </div>
  );
};

export default LLMMessage;
