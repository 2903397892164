import { memo } from "react";
import StandardButton from "./StandardButton";
import styles from "./MyAIScholarsModal.module.css";

import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../contexts/FirebaseAuth";
import { setSelectedAIScholar, setSelectedClassIndex } from "../redux/actions";
import ClassChipSmall from "./ClassChipSmall";

const MyAIScholarsModal = memo(({ onClose }) => {
  const { currentUser } = useAuth();
  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const selectedAIScholar = useSelector((state) => state.selectedAIScholar);
  const dispatch = useDispatch();

  if (!currentUser) {
    return <></>;
  }

  // const handleClassChipClick = async (index) => {
  //   dispatch(setSelectedClassIndex(index));
  //   getCurrentUserClassModerator(userClassesData[index]);
  //   getCurrentUserClassResources(userClassesData[index]);
  //   onClose();
  // };

  async function handleAddScholars() {
      //open the link gptez.com
      window.open("https://gptez.com", "_blank");
  }

  async function handleChangeScholar(scholarID) {
    console.log(scholarID);
    dispatch(setSelectedAIScholar(scholarID));
    onClose();

}

  return (
    <div className={styles.myAiScholarsModal}>
      <div className={styles.myAiScholarsModalvariant2}>
        <div className={styles.myAiScholarsWrapper}>
          <b className={styles.myAiScholars}>MY AI SCHOLARS</b>
        </div>

        <ClassChipSmall
            key={"pete"}
            classTitle={"Professor Pete"}
            classSubject={"Simple chatbot with basic knowledge in most areas."}
            propWidth="150px"
            // image17="/image-171@2x.png"
            checked={selectedAIScholar == "pete"}
            onClick={() => handleChangeScholar("pete")}
          />

          <ClassChipSmall
            key={userClassesData[selectedClassIndex]["classID"]}
            classTitle={userClassesData[selectedClassIndex]["classNumber"] + " Master"}
            classSubject={`Advanced chatbot finetuned to ` + userClassesData[selectedClassIndex]["classTitle"] + "."}
            propWidth="150px"
            // image17="/image-171@2x.png"
            checked={selectedAIScholar == userClassesData[selectedClassIndex]["classID"]}
            onClick={() => handleChangeScholar(userClassesData[selectedClassIndex]["classID"] )}
          />

        <StandardButton
          buttonText="Add or Create Scholars"
          backgroundColor="#50c7d7"
          // buttonWidth="282px"
          onClick={handleAddScholars}
        />
      </div>
    </div>
  );
});

export default MyAIScholarsModal;
