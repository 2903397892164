import { memo, useState, useRef, useCallback } from "react";
import MyAIScholarsModal from "./MyAIScholarsModal";
import PortalPopup from "./PortalPopup";
import ClassModal from "./ClassModal";
import HelpModal from "./HelpModal";
import MyAccountModal from "./MyAccountModal";
import JoinClassModal from "./JoinClassModal";
import styles from "./Header.module.css";
import { externalLinks } from '../backend/externalLinks'; // Adjust the path as needed



import { useAuth } from "../contexts/FirebaseAuth"
import { useSelector } from 'react-redux';


const Header = memo(({ renderChatSelector }) => {
  const squareandarrowup3Ref = useRef(null);
  const [isMyAIScholarsModalOpen, setMyAIScholarsModalOpen] = useState(false);
  const sharedwithyou1Ref = useRef(null);
  const [isClassModalPopupOpen, setClassModalPopupOpen] = useState(false);
  const questionmarkdiamond1Ref = useRef(null);
  const [isHelpModalPopupOpen, setHelpModalPopupOpen] = useState(false);
  const image15Ref = useRef(null);
  const [isMyAccountModalOpen, setMyAccountModalOpen] = useState(false);

  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const selectedAIScholar = useSelector((state) => state.selectedAIScholar);

  const userData = useSelector((state) => state.userData);
  const { currentUser } = useAuth()

  const userProfileFallback = require("../../src/applicationIcons/userProfileFallback.png");

  const handleImageError = (event) => {
    event.target.src = userProfileFallback;
    };

  const openMyAIScholarsModal = useCallback(() => {
    setMyAIScholarsModalOpen(true);
  }, []);

  const closeMyAIScholarsModal = useCallback(() => {
    setMyAIScholarsModalOpen(false);
  }, []);

  const openClassModalPopup = useCallback(() => {
    setClassModalPopupOpen(true);
  }, []);

  const closeClassModalPopup = useCallback(() => {
    setClassModalPopupOpen(false);
  }, []);

  const openHelpModalPopup = useCallback(() => {
    setHelpModalPopupOpen(true);
  }, []);

  const closeHelpModalPopup = useCallback(() => {
    setHelpModalPopupOpen(false);
  }, []);

  const openMyAccountModal = useCallback(() => {
    setMyAccountModalOpen(true);
  }, []);

  const closeMyAccountModal = useCallback(() => {
    setMyAccountModalOpen(false);
  }, []);

  const onSquareandarrowup2Click = useCallback(() => {
    window.open(externalLinks.helpUs);
  }, []);

  return (
    <>
      <div className={styles.frameWrapper}>
        <div className={styles.frameParent}>
          {(renderChatSelector && <div className={styles.squareandarrowup3Wrapper}>
            <button
              className={styles.squareandarrowup3}
              ref={squareandarrowup3Ref}
              onClick={openMyAIScholarsModal}
            >
              <div className={styles.allergensfill1Parent}>
                <img
                  className={styles.allergensfill1Icon}
                  alt=""
                  src="/allergensfill-1.svg"
                />
                <div className={styles.generalGptTutor}>
                  {selectedAIScholar == "pete" ? "Professor Pete" : userClassesData[selectedClassIndex]["classNumber"] + " Master"}
                </div>
              </div>
      
              <div className={styles.line3horizontal1}>
                <img className={styles.groupIcon} alt="" src="/group.svg" />
              </div>
          

              {/* <div className={styles.arrowshapeturnupbackward2}>
                <div className={styles.line3horizontal1}>
                  <img className={styles.groupIcon} alt="" src="/group2.svg" />
                </div>
              </div> */}

              
            </button>
          </div>
          )}
          <div className={styles.frameGroup}>
            <div className={styles.squareandarrowup2Wrapper}>
              <button
                className={styles.squareandarrowup2}
                onClick={onSquareandarrowup2Click}
              >
                <div className={styles.frame}>
                  <div className={styles.helpOurMission}>Help Our Mission</div>
                </div>
              </button>
            </div>
            <button
              className={styles.sharedwithyou1}
              ref={sharedwithyou1Ref}
              onClick={openClassModalPopup}
            >
              <img className={styles.groupIcon1} alt="" src="/group4.svg" />
            </button>
            <button
              className={styles.questionmarkdiamond1}
              ref={questionmarkdiamond1Ref}
              onClick={openHelpModalPopup}
            >
              <img
                className={styles.questionmarkapp2Icon}
                alt=""
                src="/questionmarkapp-2.svg"
              />
            </button>
            <div className={styles.image15Wrapper}>
              <img
                className={styles.image15}
                ref={image15Ref}
                onClick={openMyAccountModal}
                src={userData?.profilePictureURL || ""} onError={handleImageError}
                referrerPolicy="no-referrer"
              />
            </div>
          </div>
        </div>
      </div>
      {isMyAIScholarsModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Bottom left"
          relativeLayerRef={squareandarrowup3Ref}
          onOutsideClick={closeMyAIScholarsModal}
        >
          <MyAIScholarsModal onClose={closeMyAIScholarsModal} />
        </PortalPopup>
      )}
      {isClassModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Bottom right"
          relativeLayerRef={sharedwithyou1Ref}
          onOutsideClick={closeClassModalPopup}
        >
          <ClassModal onClose={closeClassModalPopup} />
        </PortalPopup>
      )}
      {isHelpModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Bottom right"
          relativeLayerRef={questionmarkdiamond1Ref}
          onOutsideClick={closeHelpModalPopup}
        >
          <HelpModal onClose={closeHelpModalPopup} />
        </PortalPopup>
      )}
      {isMyAccountModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Bottom right"
          relativeLayerRef={image15Ref}
          onOutsideClick={closeMyAccountModal}
        >
          <MyAccountModal onClose={closeMyAccountModal} />
        </PortalPopup>
      )}
    </>
  );
});

export default Header;
