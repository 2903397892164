import React, { useState, useCallback, useEffect } from "react";
import ClassChipLarge from "./ClassChipLarge";
import StandardButton from "./StandardButton";
import styles from "./ChatHistoryModal.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../contexts/FirebaseAuth";

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import ChatChip from "./ChatChip";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Stack } from "rsuite";
import { ago } from "../backend/utility";

const ChatHistoryModal = ({ onClose, classNumber }) => {
    const { currentUser } = useAuth();
  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const selectedClassUserChatsData = useSelector((state) => state.selectedClassUserChatsData);
  const dispatch = useDispatch();


  const handleChatSelect = useCallback((chatID) => {
    console.log(chatID)

    // setSelectedClasses((prevSelectedClasses) => {
    //   console.log("prev", prevSelectedClasses)
    //   if (prevSelectedClasses.includes(classId)) {
    //     return prevSelectedClasses.filter((id) => id !== classId);
    //   } else {
    //     // Check if any classes are already selected, if so, clear the selection
    //     return [...prevSelectedClasses, classId];
    //   }

    // });
  }, []);

  const sortedChats = [...selectedClassUserChatsData].sort((a, b) => b.dateCreated - a.dateCreated);

  if (!currentUser) {
    return <></>;
  }

  return (
    <>
      <div className={styles.joinClassModal}>
        <div className={styles.joinClassesModalvariant2}>
          <div className={styles.joinClassesWrapper}>
            <b className={styles.joinClasses}>{classNumber + " CHAT HISTORY"}</b>
          </div>
          <div className={styles.classChipParent}>
            <div className={styles.classOptionsWrapper}>
            {(selectedClassUserChatsData == undefined || selectedClassUserChatsData.length == 0) && (
              <Stack wrap spacing={6}>
              {[...Array(4)].map((_, index) => (
                <p key={index}>
                  <Skeleton
                    width={310}
                    height={86}
                    style={{ borderRadius: "12px", marginBottom: "-10px" }}
                    baseColor="#EFEFEF" highlightColor="#FAFAFA"
                  />
                </p>
              ))}
            </Stack>

            )}
              {(selectedClassUserChatsData == undefined || selectedClassUserChatsData.length > 0) && (
                <>
              {sortedChats.map((result, index) => (
                <div
                  key={result.chatID}
                  style={{
                    marginBottom: "6px",
                  }}
                >
                  <ChatChip
                    chatTitle={result.title}
              
                    timeAgo={ago(result.dateCreated)}
                    chatID={result.chatID}
                    messageCount={result.messages.length}
                    onClose={onClose}
                    messages={result.messages}
                  />
                </div>
              ))} 
              </>
              )}
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatHistoryModal;
