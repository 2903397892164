import { memo, useState, useEffect } from "react";
import StandardButton from "./StandardButton";
import styles from "./ClassModal.module.css";
import { leaveClass, getUser, requestModerator } from "../backend/boilerGPT";
import { useAuth } from "../contexts/FirebaseAuth"
// import convertTimestamp from "../backend/utility";
// import convertClassTitle from "../backend/utility";
import { convertClassMembership, convertTimestamp } from "../backend/utility";
import { externalLinks } from '../backend/externalLinks'; // Adjust the path as needed
import { Divider, Form, Radio, Skeleton, Space, Switch } from 'antd';
import { setSelectedClassIndex, setSelectedChatContent, setSelectedChatId } from "../redux/actions";

import UserProfile from "./UserProfile";
import { getCurrentUserClassModerator, getCurrentUserClassResources, getCurrentUserFirebase, getCurrentUserClassesFirebase } from "../backend/subscriptions";
// import { convertClassTim } from "../backend/utility";

import { useSelector, useDispatch } from "react-redux";

const ClassModal = memo(({ onClose }) => {
  const [copyClassLinkText, setCopyClassLinkText] = useState("Invite Classmates");
  const { currentUser } = useAuth();
  
  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const selectedClassModerators = useSelector((state) => state.selectedClassModerators);
  const dispatch = useDispatch();

  console.log(userClassesData);

  
  // const moderatorTextState = userClassesData[selectedClassIndex].moderatorRequests.includes(currentUser.uid) ? "Moderator Request Pending" : (userClassesData[selectedClassIndex].moderators.includes(currentUser.uid) ? "Resign as Moderator" : "Request to Become Moderator");
  const moderatorTextState = userClassesData[selectedClassIndex].moderators.includes(currentUser.uid) ? "Resign as Moderator" : "Request to Become Moderator";

  const [requestModeratorText, setRequestModeratorText] = useState(
    userClassesData[selectedClassIndex].moderators.includes(currentUser.uid) ? "Resign as Moderator" : "Request to Become Moderator" ); //TODO: change to "Request Moderator" when clicked

    useEffect(() => {
      if(selectedClassModerators.length === 0){
        getCurrentUserClassModerator(userClassesData[selectedClassIndex]);
        // getCurrentUserClassResources(userClassesData[selectedClassIndex]);
      }
    }, []);
    
    if (userClassesData === [] || userClassesData === undefined) {
      return <></>;
    }
  
    const classData = userClassesData[selectedClassIndex];
    console.log(classData);
  
    //if classNumber in not in classData, then return <></>
    if (classData.classNumber == undefined) {
      return <></>;
    }

  const copyClassLinkToClipboard = () => {
    //get the root URL of the website
    const rootURL = window.location.href.split("?")[0];
    console.log(rootURL);
    //remove any slugs from the root URL
    const rootURLNoSlugs = rootURL.split("/").slice(0, 3).join("/");
    console.log(rootURLNoSlugs);
    
    const link = rootURLNoSlugs + "?classID=" + classData.classID;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Link copied to clipboard:", link);
        setCopyClassLinkText("Class Link Copied!");
        // Perform any additional actions here
      })
      .catch((error) => {
        console.error("Failed to copy link to clipboard:", error);
        // Handle any error that occurred during copying
      });
  };

  const onClickLeaveClass = async () => {
    await leaveClass(classData.classID);
    onClose();

    //set the selected chat content
    dispatch(setSelectedChatContent([]));
    dispatch(setSelectedChatId(""));

    //refresh page
    window.location.reload();
  };

  const onModeratorClick = async () => {
    if (classData.moderators.includes(currentUser.uid)) {
      window.open(externalLinks.resignModerator);
      // await requestModerator(classData.classID);
      // setRequestModeratorText("Request to Become Moderator");
    } else {
      window.open(externalLinks.requestModerator);
      // TODO request to become moderator
      // setRequestModeratorText("Moderator Request Sent!");
    }

    // window.open("http://straightouttaharbour.com");
  };
   
    

  // const getModeratorsData = async () => {
  //   if (userClassesData.length === 0 || userClassesData === undefined) {
  //     return;
  //   }

  //   var moderatorsData = await Promise.all(
  //     classData.moderators.concat(classData.teachers).map((userID) => getUser(userID))
  //   );
  //   moderatorsData = moderatorsData.map((data) => data.data.data);
  //   console.log("MODER", moderatorsData)
  //   setClassModerators(moderatorsData);
  // };


  return (
    <div className={styles.classModal}>
      <div className={styles.classCommunityModalvariant3}>
        <div className={styles.shareClassWrapper}>
          <b className={styles.shareClass}>SHARE CLASS</b>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.image17Parent}>
            <img
              className={styles.image17Icon}
              alt=""
              // src="/image-171@2x.png"
            />
            <div className={styles.ma265Spring2022Parent}>
              <b className={styles.shareClass}>
                {classData && classData.classNumber && classData.term
                  ? `${classData.classNumber} ${classData.term}`
                  : ""}
              </b>

              <div className={styles.linearAlgebra}>{classData.classTitle}</div>
            </div>
          </div>
        </div>
        <StandardButton
          buttonText={copyClassLinkText}
          backgroundColor="#50c7d7"
          buttonWidth="282px"
          onClick={copyClassLinkToClipboard}
        />
        <div className={styles.classCommunityModalvariant3Inner}>
          <img className={styles.frameChild} alt="" src="/line-31.svg" />
        </div>
        <div className={styles.shareClassWrapper}>
          <b className={styles.shareClass}>CLASS STATISTICS</b>
        </div>
        <div className={styles.chatsWrapper}>
            <b className={styles.myAccount}>{parseInt(classData.resourceIDs.length) + " Resources Uploaded"}</b>
          </div>
          <div className={styles.chatsWrapper}>
            {/* <b className={styles.myAccount}>{parseInt(userData.classesModerating.length + userData.classesStudent.length + userData.classesTeaching.length) + " Classes Joined"}</b> */}
          </div>
        <div className={styles.classCommunityModalvariant3Inner}>
          <img className={styles.frameChild} alt="" src="/line-31.svg" />
        </div>
        <div className={styles.shareClassWrapper}>
          <b className={styles.shareClass}>CLASS COMMUNITY</b>
        </div>
        <div className={styles.classMembersParent}>
          <b className={styles.shareClass}>
            {convertClassMembership(classData)}
          </b>
          <div className={styles.linearAlgebra}>
            {"Since " + convertTimestamp(classData.dateCreated)}
          </div>
        </div>
        <div className={styles.chatsWrapper}>
          <b className={styles.shareClass}>
            {classData.moderators.length === 1
              ? classData.moderators.length + " Class Moderator"
              : classData.moderators.length + " Class Moderators"}
          </b>
        </div>
        <div className={styles.frameDiv}>
          {selectedClassModerators.length > 0 &&  (selectedClassModerators.map((data, index) => (
            console.log("DATA", data),
            <UserProfile
            key={index}
              userName={data.firstName + " " + data.lastName}
              dateCreated={"Since " + convertTimestamp(data.dateJoined)}
              profilePictureURL={data.profilePictureURL}
            />
          )))}

          {(selectedClassModerators.length == 0 && classData.moderators.length > 0) &&  (selectedClassModerators.map((data, index) => (
            console.log("DATA", data),
            <Space>
              <Skeleton.Avatar active  />
              <Skeleton.Input active />
            </Space>
          )))}
        </div>
        <StandardButton
          buttonText={requestModeratorText}
          backgroundColor="#efefef"
          buttonWidth="282px"
          onClick={onModeratorClick}
        />
        <StandardButton
          buttonText="Leave Class"
          backgroundColor="#e43e52"
          buttonWidth="282px"
          onClick={onClickLeaveClass}
        />
      </div>
    </div>
  );
});

export default ClassModal;
