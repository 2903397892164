import { memo, useState, useRef, useCallback, useEffect } from "react";
import MyClassesModal from "./MyClassesModal";
import JoinClassModal from "./JoinClassModal";
import ChatHistoryModal from "./ChatHistoryModal";
import CreateClassModal from "./CreateClassModal";
import PortalPopup from "./PortalPopup";
import styles from "./Sidebar.module.css";
import SidebarButton from "./SidebarButton";
import { convertClassMembership, convertTimestamp } from "../backend/utility";
import { useSelector } from "react-redux";
import {Link, useNavigate} from 'react-router-dom'
import { useLocation } from 'react-router-dom';


import {
  getCurrentUserClassModerator,
  getCurrentUserClassResources,
  getCurrentUserClassChats,
} from "../backend/subscriptions";
import { setSelectedChatContent, setSelectedChatId } from '../redux/actions';

import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import store from "../redux/store";


const Sidebar = memo(() => {
  const frameButtonRef = useRef(null);
  const chatButtonRef = useRef(null);
  const [isMyClassesModalOpen, setMyClassesModalOpen] = useState(false);
  const [isChatHistoryModalOpen, setChatHistoryModalOpen] = useState(false);
  const [isJoinClassModalOpen, setJoinClassModalOpen] = useState(false);
  const [isCreateClassModalOpen, setCreateClassModalOpen] = useState(false);
  const currentPath = window.location.pathname;
  const [selected, setSelected] = useState(currentPath);

  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const selectedClassModerators = useSelector(
    (state) => state.selectedClassModerators
  );
  const classData = userClassesData[selectedClassIndex];

  const location = useLocation();

  useEffect(() => {
    setSelected(location.pathname);
  }, [location.pathname]);


  const navigate = useNavigate();

  useEffect(() => {
    if (selectedClassModerators.length === 0) {
      console.log("useEffect in Sidebar.js");
      //if classID in userClassesData[selectedClassIndex] is not undefined, then get the moderators
      if(userClassesData[selectedClassIndex] !== undefined){
      getCurrentUserClassModerator(userClassesData[selectedClassIndex]);
      getCurrentUserClassResources(userClassesData[selectedClassIndex]);
      getCurrentUserClassChats(userClassesData[selectedClassIndex]);
      }
      
    }
  }, []);

  const openMyClassesModal = useCallback(() => {
    setMyClassesModalOpen(true);
  }, []);

  const closeMyClassesModal = useCallback(() => {
    setMyClassesModalOpen(false);
  }, []);

  const openChatHistoryModal = useCallback(() => {
    setChatHistoryModalOpen(true);
  }, []);

  const closeChatHistoryModal = useCallback(() => {
    setChatHistoryModalOpen(false);
  }, []);

  const openJoinClassModal = useCallback(() => {
    console.log("open join class modal");
    setJoinClassModalOpen(true);
  }, []);

  const closeJoinClassModal = useCallback(() => {
    setJoinClassModalOpen(false);
  }, []);

  const openCreateClassModal = useCallback(() => {
    console.log("open create class modal");
    setCreateClassModalOpen(true);
  }, []);

  const closeCreateClassModal = useCallback(() => {
    setCreateClassModalOpen(false);
  }, []);

  const handleNewChat = useCallback(() => {
    navigate("/dashboard/learn")

    console.log("new chat");
    store.dispatch(setSelectedChatContent([]));
    store.dispatch(setSelectedChatId(""));
  }, []);

  return (
    <>
      <div className={styles.frameParent}>
          <div className={styles.frameParent100}>
            <button
              className={styles.frameGroup}
              ref={frameButtonRef}
              onClick={openMyClassesModal}
            >
              <div className={styles.image17Parent}>
                <img
                  className={styles.image17Icon}
                  alt=""
                  // src="/image-171@2x.png"
                />
                <div className={styles.cgt163Winter2023Parent}>
                  <b className={styles.cgt163Winter}>
                    {classData
                      ? classData.classNumber + " " + classData.term
                      : ""}
                  </b>
                  <div className={styles.computerAidedDesign}>
                    {classData ? classData.classTitle : ""}
                  </div>
                </div>
              </div>

              <div className={styles.iconmonstrCheckMark151}>
                <div className={styles.frame}>
                  <div className={styles.line3horizontal1}>
                    <img className={styles.groupIcon} alt="" src="/group.svg" />
                  </div>
                </div>
              </div>
            </button>
            <div className={styles.chatWithResourcesWrapper}>
              <b className={styles.chatWithResources}>CHAT WITH RESOURCES</b>
            </div>
            <div className={styles.frameContainer}>
              <SidebarButton
                iconPath="/brain-1.svg"
                buttonText="Learn Concepts"
                slug="/dashboard/learn"
                selected={selected === "/dashboard/learn"}
                setSelected={setSelected}
              />
              {/* <SidebarButton
            iconPath="/persontextrectangle-1.svg"
            buttonText="Assignment Assistant"
            slug="/dashboard/assignments"
            selected={selected === "/dashboard/assignments"}
            setSelected={setSelected}
          /> */}
              {/* <SidebarButton
                iconPath="/group1.svg"
                buttonText="Practice Problems"
                slug="/dashboard/practice"
                selected={selected === "/dashboard/practice"}
                setSelected={setSelected}
              /> */}
            </div>
            <div className={styles.chatWithResourcesWrapper}>
              <b className={styles.chatWithResources}>DISCOVER RESOURCES</b>
            </div>
            <div className={styles.frameContainer}>
              <SidebarButton
                iconPath="/circlehexagongrid-1.svg"
                buttonText="Class Resources"
                slug="/dashboard/class-resources"
                selected={selected === "/dashboard/class-resources"}
                setSelected={setSelected}
              />
              <SidebarButton
                iconPath="/squaregrid3x3-1.svg"
                buttonText="Topic Resources"
                slug="/dashboard/topic-resources"
                selected={selected === "/dashboard/topic-resources"}
                setSelected={setSelected}
              />
            </div>
            <div className={styles.chatWithResourcesWrapper}>
              <b className={styles.chatWithResources}>IMPROVE CLASS</b>
            </div>
            <div className={styles.frameContainer}>
              {/* <SidebarButton
                iconPath="/shareplay-1.svg"
                buttonText="Class Consensus"
                slug="/dashboard/consensus"
                selected={selected === "/dashboard/consensus"}
                setSelected={setSelected}
              /> */}
              <SidebarButton
                iconPath="/checkmarkicloud-1.svg"
                buttonText="Add Resources"
                slug="/dashboard/add"
                selected={selected === "/dashboard/add"}
                setSelected={setSelected}
              />
            </div>
            </div>

          <div className={styles.frameParent3}>
            <button
              className={styles.frameButton88}
              onClick={handleNewChat}
            >
              <div className={styles.frameWrapper88}>
                <div className={styles.newChatWrapper88}>
                  <b className={styles.cgt163Winter}>New Chat</b>
                </div>
              </div>
              <div className={styles.iconmonstrCheckMark15188}>
                <div className={styles.frame88}>
                  <div className={styles.line3horizontal188}>
                    <PlusOutlined
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    />
                  </div>
                </div>
              </div>
            </button>
            <button
              className={styles.frameChild88}
              onClick={openChatHistoryModal}
              ref={chatButtonRef}
            >
              <HistoryOutlined
                style={{ fontSize: "16px", fontWeight: "bold" }}
              />
            </button>
          </div>

      </div>
      {isMyClassesModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Bottom left"
          relativeLayerRef={frameButtonRef}
          onOutsideClick={closeMyClassesModal}
        >
          <MyClassesModal
            onClose={closeMyClassesModal}
            openJoinClassModal={openJoinClassModal}
          />
        </PortalPopup>
      )}
      {isJoinClassModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Centered"
          onOutsideClick={closeJoinClassModal}
        >
          <JoinClassModal
            onClose={closeJoinClassModal}
            openCreateClassModal={openCreateClassModal}
          />
        </PortalPopup>
      )}
      {isCreateClassModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Centered"
          onOutsideClick={closeCreateClassModal}
        >
          <CreateClassModal onClose={closeCreateClassModal} />
        </PortalPopup>
      )}
      {isChatHistoryModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Top right"
          right={-75}
          relativeLayerRef={chatButtonRef}
          onOutsideClick={closeChatHistoryModal}
        >
          <ChatHistoryModal
            onClose={closeChatHistoryModal}
            classNumber={classData.classNumber}
          />
        </PortalPopup>
      )}
    </>
  );
});

export default Sidebar;
