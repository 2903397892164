import { memo } from "react";
import StandardButton from "./StandardButton";
import styles from "./MyClassesModal.module.css";
import ClassChipSmall from "./ClassChipSmall";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../contexts/FirebaseAuth";
import { setSelectedClassIndex, setSelectedChatContent, setSelectedChatId } from "../redux/actions";
import { convertClassTitle } from "../backend/utility";
import { getCurrentUserClassModerator, getCurrentUserClassResources, getCurrentUserClassChats } from "../backend/subscriptions";

const MyClassesModal = memo(({ onClose, openJoinClassModal }) => {
  const { currentUser } = useAuth();
  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const dispatch = useDispatch();

  if (!currentUser) {
    return <></>;
  }

  const handleClassChipClick = async (index) => {
    dispatch(setSelectedClassIndex(index));
    dispatch(setSelectedChatContent([]));
    dispatch(setSelectedChatId(""));
    getCurrentUserClassModerator(userClassesData[index]);
    getCurrentUserClassResources(userClassesData[index]);
    getCurrentUserClassChats(userClassesData[index]);
    onClose();
  };

  return (
    <div className={styles.myClassesModal}>
      <div className={styles.myClassesModalvariant3}>
        <div className={styles.myClassesWrapper}>
          <b className={styles.myClasses}>MY CLASSES</b>
        </div>

        {userClassesData.map((data, index) => (
          <ClassChipSmall
            key={index}
            classTitle={convertClassTitle(data)}
            classSubject={data.classTitle}
            propWidth="150px"
            // image17="/image-171@2x.png"
            checked={index === selectedClassIndex}
            onClick={() => handleClassChipClick(index)}
          />
        ))}

        <StandardButton
          buttonText="Join Classes"
          // buttonWidth="282px"
          backgroundColor="#50c7d7"
          onClick={() => {
            openJoinClassModal();
            onClose();
          }}
        />
      </div>
    </div>
  );
});

export default MyClassesModal;
