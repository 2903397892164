import ResourceHeader from "../components/ResourceHeader";
import styles from "./ClassResources.module.css";
import { useNavigate, Navigate } from "react-router-dom";
import firebase from "firebase/app";
import { useAuth } from "../contexts/FirebaseAuth";
import ResourceChip from "../components/ResourceChip";
import { memo, useState, useRef, useCallback } from "react";
import CreateResourceModal from "../components/CreateResourceModal";
import PortalPopup from "../components/PortalPopup";
import { useSelector, useDispatch } from "react-redux";
import { ago } from "../backend/utility";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Empty } from 'antd';

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

import "rsuite/dist/rsuite.min.css";
import { Stack } from "rsuite";

import { Divider, Typography } from 'antd';
const { Title, Paragraph, Text, Link } = Typography;


const ClassResources = () => {
  const { currentUser } = useAuth();
  const [isCreateResourceModalOpen, setCreateResourceModalOpen] =
    useState(false);
  const selectedClassResources = useSelector(
    (state) => state.selectedClassResources
  );

  const openCreateResourceModal = useCallback(() => {
    setCreateResourceModalOpen(true);
  }, []);

  const closeCreateResourceModal = useCallback(() => {
    setCreateResourceModalOpen(false);
  }, []);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className={styles.frameParent4}>
        {/* <ResourceHeader
          title="Class Resources"
          onAdd={openCreateResourceModal}
        /> */}
        <Title>Class Resources</Title>
        <div className={styles.frameParent5}>
        {selectedClassResources
          .filter(
            (resource) =>
              resource.status === "Accepted" &&
              resource.resourceType === "classSpecific"
          )
          .sort((a, b) => b.views - a.views)
          .map((data, index) => (
            <ResourceChip
              key={index}
              resourceID={data.resourceID}
              title={data.resourceTitle}
              uploadedBy={data.creatorName}
              rawMediaURL={data.rawMediaURL}
              timeAgo={ago(data.dateModified)}
              views={data.views}
              modality={data.modality}
              upvotes={data.upvotes.length}
              downvotes={data.downvotes.length}
              votable={true}
              status={data.status}
              creatorID={data.creatorID}
            />
          ))}
          {selectedClassResources.filter(
            (resource) =>
              resource.status === "Accepted" &&
              resource.resourceType === "classSpecific"
          ).length == 0 && (
            <Stack wrap spacing={12}>
                {/* {[...Array(8)].map((_, index) => (
                  <p key={index}>
                    <Skeleton
                      width={300}
                      height={110}
                      style={{ borderRadius: "12px" }}
                      baseColor="#EFEFEF" highlightColor="#FAFAFA"
                    />
                  </p>
                ))} */}
                <Empty description="No Class Resources Yet" />
              </Stack>
          
            )}
          </div>
      </div>
      {isCreateResourceModalOpen && (
        <PortalPopup
          overlayColor="rgba(55, 53, 47, 0.6)"
          placement="Centered"
          onOutsideClick={closeCreateResourceModal}
        >
          <CreateResourceModal
            onClose={closeCreateResourceModal}
            openCreateClassModal={openCreateResourceModal}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ClassResources;
