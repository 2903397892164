import React, { useState, useCallback, useEffect } from "react";
import ClassChipLarge from "./ClassChipLarge";
import StandardButton from "./StandardButton";
import styles from "./JoinClassModal.module.css";
import { convertTimestamp } from "../backend/utility";
import { searchClasses } from "../backend/boilerGPT";
import { sumClassUsers } from "../backend/utility";
import { joinClass } from "../backend/boilerGPT";

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { getCurrentUserClassesFirebase } from "../backend/subscriptions";

const JoinClassModal = ({
  onClose,
  openCreateClassModal,
  onboarding = false,
}) => {
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [searching, setSearching] = useState(false);

  const handleSearch = useCallback(async () => {
    setSearching(true);
    const results = await searchClasses(searchString);
    console.log(results);
    setSearchResults(results.data.results);
    setSearching(false);
  }, [searchString]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleSearchStringChange = useCallback((event) => {
    //Make it uppercase to match the backend
    setSearchString(event.target.value.toUpperCase());
  }, []);

  const handleClassSelect = useCallback((classId) => {
    console.log(classId);
    setSelectedClasses((prevSelectedClasses) => {
      console.log("prev", prevSelectedClasses);
      if (prevSelectedClasses.includes(classId)) {
        return prevSelectedClasses.filter((id) => id !== classId);
      } else {
        // Check if any classes are already selected, if so, clear the selection
        return [...prevSelectedClasses, classId];
      }
    });
  }, []);

  const joinSelectedClasses = useCallback(async () => {
    console.log("SELECTED", selectedClasses);
    try {
      for (const classID of selectedClasses) {
        console.log(classID);
        await joinClass(classID);
      }
      // Clear selected classes after successful join
      setSelectedClasses([]);

      // Optional: Show a success message or perform additional actions
      console.log("Successfully joined selected classes!");
      onClose();

      //wait for half a second before reloading
      if (!onboarding) {
        await new Promise((r) => setTimeout(r, 500));
        getCurrentUserClassesFirebase();
      }
    } catch (error) {
      // Handle error if join fails
      console.error("Error joining classes:", error);
      onClose();
    }
  }, [selectedClasses]);

  return (
    <>
      <div className={styles.joinClassModal}>
        <div className={styles.joinClassesModalvariant2}>
          <div className={styles.joinClassesWrapper}>
            <b className={styles.joinClasses}>JOIN CLASSES</b>
          </div>
          <div className={styles.searchForClassesToJoinParent}>
            <b className={styles.joinClasses}>Search for Classes to Join</b>
            <div className={styles.queryByCourse}>
              {"Query by course code, semester, or class subject."}
            </div>
          </div>
          <div className={styles.joinClassesModalvariant2Inner}>
            <div className={styles.frameWrapper}>
              <input
                className={styles.frameChild}
                type="text"
                placeholder="Example: CNIT 340"
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </div>
          </div>
          <div className={styles.classChipParent}>
            <div className={styles.classOptionsWrapper}>
              {!searching && searchResults.length == 0 && (
                <button
                className={styles.classChip2}
                onClick={() => {
                  openCreateClassModal();
                  onClose();
                }}
              >
                <div className={styles.frameDiv}>
                  <div className={styles.frameContainer}>
                    <div className={styles.createNewClassWrapper}>
                      <b className={styles.createNewClass}>+ Create New Class</b>
                    </div>
                  </div>
                </div>
              </button>
              )}
              {searching && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Spinner
                    color="#000000"
                    size={20}
                    speed={1.5}
                    animating={true}
                  />

                  <div style={{ height: "10px" }}></div>
                  {/* <div className={styles.queryByCourse}>
                    {"Loading Classes..."}
                  </div> */}
                </div>
              )}
              {searchResults.map((result, index) => (
                <div
                  key={result.classID}
                  style={{
                    marginBottom: "6px",
                  }}
                >
                  <ClassChipLarge
                    courseCode={result.classNumber + " " + result.term}
                    courseTopic={result.classTitle}
                    courseInfo={
                      (sumClassUsers(result) == "1"
                        ? "1 Student"
                        : sumClassUsers(result) + " Students") +
                      " · " +
                      convertTimestamp(result.dateCreated)
                    }
                    onClick={() => handleClassSelect(result.classID)}
                    selected={selectedClasses.includes(result.classID)}
                  />
                </div>
              ))}
            </div>
          </div>
          <StandardButton
            buttonText="Join Selected Classes"
            backgroundColor="#50c7d7"
            buttonWidth="310px"
            onClick={joinSelectedClasses}
          />
        </div>
      </div>
    </>
  );
};

export default JoinClassModal;
