// Markdown.tsx

import { FC } from "react";
import ReactMarkdown from "react-markdown";
import Mermaid from "./Mermaid";
// import { Remark } from 'react-remark';

import { a11yDark } from "react-syntax-highlighter/dist/cjs/styles/prism";

import { useState } from "react";
import { usePython } from "react-py";

import styles from "../../pages/LearnConcepts.module.css"; // Assuming you have a CSS module file for styling
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeHighlight from "rehype-highlight";
import toc from "remark-toc";
import remarkImages from "remark-images";
// import codeScreenshot from 'remark-code-screenshot'

import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you

import rangeParser from "parse-numeric-range";
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism";

import React, { Component } from "react";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

import arduino from "react-syntax-highlighter/dist/cjs/languages/hljs/arduino";
import { arduinoLight } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import "highlight.js/styles/arduino-light.css";
import rehypeMermaid from "rehype-mermaidjs";
import CodeCopyBtn from "./codeCopyBtn";

const codesandbox = require("remark-codesandbox");
// var mermaid = require('remark-mermaid');
// import remarkMermaid from '@southball/remark-mermaid';

type MarkdownProps = {
  text;
};

const Markdown: FC<MarkdownProps> = ({ text }) => {
  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );

  const packages = {
    official: ['pyodide-http', 'scipy, sympy'],
    micropip: [],
  }

  return (
    <ReactMarkdown
      className={styles.onceUponA}
      remarkPlugins={[remarkGfm, remarkMath, toc, remarkImages]}
      rehypePlugins={[rehypeKatex]} //rehypeHighlight
      components={{
        pre: Pre,
        code({ node, inline, className = "blog-code", children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          console.log(match);
          return !inline && match ? (
            <div>
              <SyntaxHighlighter
                style={a11yDark}
                language={match[1]}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>

              {(match[1] == "html" || match[1] == "xml") ? (
                <div dangerouslySetInnerHTML={{ __html: children }} />
              ) : (
                <div>
                </div>
              )}

              {match[1] == "python" ? (
                <div>
                  {/* <Codeblock code={String(children).replace(/\n$/, "")} /> */}
                </div>
              ) : (
                <div>
                </div>
              )}

            {match[1] == "mermaid" ? (
                <div>
                  <Mermaid chart={String(children).replace(/\n$/, "")} />
                  {/* <Codeblock code={String(children).replace(/\n$/, "")} /> */}
                </div>
              ) : (
                <div>
                </div>
              )}  
            </div>
          ) : (
              <code className={"blog-code"} {...props}>
                {children}
              </code>
          );
        },
      }}
    >
      {text}
    </ReactMarkdown>
    // <Remark remarkPlugins={[remarkMath, toc, remarkImages, codesandbox]} rehypePlugins={[rehypeKatex, rehypeHighlight]} >
    //     {text}
    // </Remark>
  );
};

export default Markdown;

function Codeblock(code: String) {
  const { runPython, stdout, stderr, isLoading, isRunning } = usePython();

  try {
    // console.log(code["code"]);
    // const [input, setInput] = useState("");

    // Use the usePython hook to run code and access both stdout and stderr

    return (
      <>
        <div style={{ position: 'relative',  display: 'flex', justifyContent: 'right', alignItems: 'right', cursor: 'pointer', backgroundColor: 'transparent' }}>
          <button
            className={styles.questionmarkdiamond1}
            //   ref={questionmarkdiamond1Ref}
            onClick={(e) => {
              try {
                e.preventDefault();
                runPython(code["code"]);
              } catch (error) {
                console.error('Error running Python code:', error);
              }
            }}
            style={{ position: 'absolute', right: '15px', top: '20px' }}
          >
            <img
              className={styles.questionmarkapp2Icon}
              alt=""
              src="/run.svg"
            />
          </button>
        </div>

        {isLoading ? <p>Loading Python Interpreter...</p> : <p>Python Interpreter Ready!</p>}

        {/* <p>Output</p> */}
        <pre>
          <code>{stdout}</code>
        </pre>
        {/* <p>Error</p> */}
        <pre>
          <code>{stderr}</code>
        </pre>
      </>
    );
  } catch (error) {
    console.error('Error in Codeblock function:', error);
    return null; // or you can return an error message/component or handle it as needed
  }
}
