import { memo } from "react";
import styles from "./ClassChipLarge.module.css";
const ClassChipLarge = memo(
  ({
    courseCode,
    courseTopic,
    courseInfo,
    courseSchoolImage = "/image-17@2x.png",
    onClick,
    selected,
  }) => {
    return (
      <button className={selected ? styles.classChipSelected : styles.classChip} onClick={onClick}>
        <div className={styles.classChipInner}>
          <div className={styles.image17Parent}>
            <img
              className={styles.image17Icon}
              alt=""
              src={courseSchoolImage}
            />
            <div className={styles.cs159Fall2022Parent}>
              <b className={styles.cs159Fall}>{courseCode}</b>
              <b className={styles.introToComputer}>{courseTopic}</b>
              <div className={styles.studentsNovember}>{courseInfo}</div>
            </div>
          </div>
        </div>
      </button>
    );
  }
);

export default ClassChipLarge;
