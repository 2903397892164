import { FunctionComponent, useEffect } from "react";
import React from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
//import GoogleButton from 'react-google-button'
import { useNavigate, Navigate } from "react-router-dom";
// import firebase from "firebase/app";
import { useAuth } from "../contexts/FirebaseAuth";
import { joinClass, uploadMicrosoftPFP } from "../backend/boilerGPT";
import { externalLinks } from "../backend/externalLinks";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import app from "../firebase";
import { createUser } from "../backend/boilerGPT";

import ClassChipSmall from "../components/ClassChipSmall";
import styles from "./SignInScreen.module.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigationType,
  useLocation,
} from "react-router-dom";

const SignInScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [referralClassEnabled, setReferralClassEnabled] = React.useState("");

  useEffect(() => {
    async function checkForReferralLink() {
      try {
        const currentUser = app.auth().currentUser;
        const searchParams = new URLSearchParams(location.search);
        const classID = searchParams.get("classID");
        // console.log("CLASS ID", classID);

        if (classID === null || classID === undefined) {
          return;
        }
        if (currentUser) {
          await joinClass(classID);
        } else {
          setReferralClassEnabled(classID);
        }
      } catch {
        console.log("No Referral Link");
      }
    }
    checkForReferralLink();
  }, []);

  if (currentUser) {
    return <Navigate to="/dashboard/learn" />;
  }

  async function handleGoogleLogin(event) {
    event.preventDefault();

    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.email"); // see: https://developers.google.com/identity/protocols/oauth2/scopes

    const result = await app.auth().signInWithPopup(provider);
    //const result = await app.auth().signInWithRedirect(provider)

    var user = result.user;
    console.log("USER", user);

    if (user === null || user === undefined) {
      console.log("USER IS NULL");
      return;
    }

    if (
      result.additionalUserInfo === null ||
      result.additionalUserInfo === undefined ||
      result.credential === null ||
      result.credential === undefined
    ) {
      console.log("USER IS NULL");
      return;
    }

    var providerId = result.additionalUserInfo.providerId;
    var isNewUser = result.additionalUserInfo.isNewUser;
    var profile = result.additionalUserInfo.profile;
    var token = result.credential.accessToken;
    console.log("USER:", user); // user.uid, user.displayName, user.email, user.emailVerified, user.phoneNumber, user.photoURL, user.refreshToken
    console.log("PROVIDER:", providerId);
    console.log("USER PROFILE:", profile);
    console.log("ACCESS TOKEN", token);
    console.log("ALL:", result);
    console.log("NEW USER", isNewUser);

    if (isNewUser) {
      if (user.displayName.split(" ").length === 2) {
        console.log("FIRST NAME", user.displayName.split(" ")[0]);
        console.log("LAST NAME", user.displayName.split(" ")[1]);
        createUser(
          user.uid,
          user.displayName.split(" ")[0],
          user.displayName.split(" ")[1],
          user.email
        );
      } else {
        createUser(user.uid, user.displayName, "", user.email);
      }
    }

    console.log("LOGIN SUCCESS");
    if (referralClassEnabled !== "") {
      await joinClass(referralClassEnabled);
    }
    if (isNewUser) {
      navigate("/onboarding");
    } else {
      navigate("/dashboard/learn");
    }
  }

  function downloadFile(file, fileName) {
    // Create a temporary link element
    const link = document.createElement("a");

    // Set the href attribute to the file object URL
    link.href = URL.createObjectURL(file);

    // Set the download attribute to specify the desired file name
    link.download = fileName;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the object URL
    URL.revokeObjectURL(link.href);
  }

  const lookupMsAzureProfilePhoto = (accessToken) => {
    fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "image/jpg",
      },
    })
      .then(async function (response) {
        return await response.blob();
      })
      .then(function (blob) {
        //turn into a file object
        const file = new File([blob], "profile.jpg", { type: "image/jpeg" });

        // downloadFile(file, "profile.jpg");
        //here, call a custom endpoint to upload the file
        // Call the uploadMicrosoftPFP function to upload the profile photo
        uploadMicrosoftPFP(file)
          .then((uploadRes) => {
            // Handle success or error response from uploading profile photo
            if (uploadRes.success) {
              console.log(
                "Profile photo uploaded successfully:",
                uploadRes.imageURL
              );
              // Update UI or take further actions if needed
            } else {
              console.log("Error uploading profile photo:", uploadRes.error);
              // Handle error and show appropriate message to the user
            }
          })
          .catch((uploadErr) => {
            console.log("Error uploading profile photo:", uploadErr);
            // Handle error and show appropriate message to the user
          });

        const imageObjectURL = URL.createObjectURL(blob);
        // imageObjectURL will be e.g. blob:http://localhost:3000/f123c12a-1234-4e30-4321-af32f2c5e5bc
        // so updating the <img scr=""> will present the image correctly after
        // this function runs
        // setProfilePicUrl(imageObjectURL);
        console.log(imageObjectURL);
      })
      .catch((e) => console.log("error injecting photo"));
  };

  async function handleMicrosoftLogin(event) {
    // setLoader(prevState => ({ ...prevState, microsoftLoading: true }));
    // const provider = new firebase.auth.OAuthProvider('microsoft.com');
    // firebase.auth().signInWithPopup(provider).then(data => {
    //     console.log(data);
    //     setLoader(prevState => ({ ...prevState, microsoftLoading: false }));
    // })

    event.preventDefault();

    var provider = new firebase.auth.OAuthProvider("microsoft.com");

    // provider.addScope("https://www.googleapis.com/auth/userinfo.email") // see: https://developers.google.com/identity/protocols/oauth2/scopes

    const result = await app.auth().signInWithPopup(provider);
    console.log(result);
    //const result = await app.auth().signInWithRedirect(provider)

    // const credential = firebase.auth.OAuthProvider.credentialFromResult(result);
    // const accessToken = credential.accessToken;
    // const idToken = credential.idToken;

    var user = result.user;

    if (user === null || user === undefined) {
      console.log("USER IS NULL");
      return;
    }

    if (
      result.additionalUserInfo === null ||
      result.additionalUserInfo === undefined ||
      result.credential === null ||
      result.credential === undefined
    ) {
      console.log("USER IS NULL");
      return;
    }

    var providerId = result.additionalUserInfo.providerId;
    var profile = result.additionalUserInfo.profile;
    var isNewUser = result.additionalUserInfo.isNewUser;
    var token = result.credential.accessToken;
    console.log("USER:", user); // user.uid, user.displayName, user.email, user.emailVerified, user.phoneNumber, user.photoURL, user.refreshToken
    console.log("PROVIDER:", providerId);
    console.log("USER PROFILE:", profile);
    console.log("ACCESS TOKEN", token);
    console.log("ALL:", result);
    if (isNewUser) {
      await createUser(
        user.uid,
        profile.givenName.split(" ")[0],
        profile.surname,
        user.email
      );

      lookupMsAzureProfilePhoto(result.credential.accessToken);
    }

    console.log("LOGIN SUCCESS");
    if (referralClassEnabled !== "") {
      await joinClass(referralClassEnabled);
    }

    if (isNewUser) {
      navigate("/onboarding");
    } else {
      navigate("/dashboard/learn");
    }
  }

  return (
    <div className={styles.signInScreen}>
      <div className={styles.titleContainer}>
        <b className={styles.title}>BoilerGPT</b>
        <b
          className={styles.subtitle}
        >{`Your Copilot for Purdue Education. `}</b>
      </div>
      <div className={styles.featuresList}>
        <ClassChipSmall
          classTitle="Chat with Class Notes"
          classSubject="Get answers to any question imaginable- with links back to the original class resources."
          getAnswersToAnyQuestionsYBorderRadius="6px"
          propWidth="210px"
          frame30AlignSelf="stretch"
          frame30BackgroundColor="unset"
          frame30Width="unset"
          // image17="/image-1711@2x.png"
          vector="/vector11.svg"
          checked={true}
        />
        <ClassChipSmall
          classTitle="Walkthrough Homework"
          classSubject="Practice problems like you’ve never seen them before."
          getAnswersToAnyQuestionsYBorderRadius="6px"
          propWidth="201px"
          frame30AlignSelf="stretch"
          frame30BackgroundColor="unset"
          frame30Width="unset"
          // image17="/image-1711@2x.png"
          vector="/vector11.svg"
          checked={true}
        />
        <ClassChipSmall
          classTitle="Build Class Consensus"
          classSubject="Discover class opinions about exams, homework, etc."
          propWidth="220px"
          getAnswersToAnyQuestionsYBorderRadius="6px"
          frame30AlignSelf="stretch"
          frame30BackgroundColor="unset"
          frame30Width="unset"
          // image17="/image-1711@2x.png"
          vector="/vector11.svg"
          checked={true}
        />
      </div>
      <div className={styles.continueButtons}>
        {/* <button className={styles.googleContinue} onClick={handleGoogleLogin}>
          <img
            className={styles.google64Copy1}
            alt=""
            src="/google64-copy-1@2x.png"
          />
          <b className={styles.continueWithGoogle}>Continue With Google</b>
        </button> */}
        <button
          className={styles.microsoftContinue}
          onClick={handleMicrosoftLogin}
        >
          <img
            className={styles.google64Copy1}
            alt=""
            src="/microsoft64-copy-1@2x.png"
          />
          <b className={styles.continueWithGoogle}>
            Continue With Purdue Email
          </b>
        </button>
        <div className={styles.notPurdue}>
          Not a Purdue student?{" "}
          <a href={externalLinks.newSchool} target="_blank">
            Learn More.
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
