import { memo } from "react";
import styles from "./ChatChip.module.css";
import {
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  viewResource,
  upvoteResource,
  downvoteResource,
  approveResource,
  rejectResource,
  deleteResource,
  deleteChat
} from "../backend/boilerGPT";

import { Dropdown, Space } from "antd";
import { getCurrentUserClassChats } from "../backend/subscriptions";

import { useSelector, useDispatch } from "react-redux";
import {Link, useNavigate} from 'react-router-dom'
import store from "../redux/store";
import { setSelectedChatContent, setSelectedChatId } from "../redux/actions";
import { useEffect } from "react";

const ChatChip = memo(
  ({
    chatTitle,
    timeAgo,
    chatID,
    messageCount,
    onClose,
    messages
  }) => {
    const userClassesData = useSelector((state) => state.userClassesData);
    const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
    const selectedClassModerators = useSelector((state) => state.selectedClassModerators);
    const selectedChatContent = useSelector((state) => state.selectedChatContent);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      getCurrentUserClassChats(userClassesData[selectedClassIndex])
    }, []);

    const handleDelete = async () => {
      deleteChat(chatID)
      //wait 2 seconds
      await new Promise((r) => setTimeout(r, 1000));
      getCurrentUserClassChats(userClassesData[selectedClassIndex])
    };

    const handleOpenChat = async () => {
      navigate("/dashboard/learn")
      console.log("Opening chat");
      console.log(chatID);
      onClose();
      
      //set the selected chat content
      store.dispatch(setSelectedChatContent(messages))
      store.dispatch(setSelectedChatId(chatID))
        
    };

    const onClick = ({ key }) => {
      console.log(`Click on item ${key}`);
      if (key === "2") {
        handleDelete();
      } else if (key === "1") {
        handleOpenChat();
    } 
    };

    let items = [
      {
        key: "1",
        icon: <EyeOutlined />,
        label: "Open Chat",
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Delete Chat",
        danger: true,
      }
    ];

    return (
      <button className={styles.resourceChip}>
        <div className={styles.resourceChipInner}>
          <div className={styles.freshmanYear20212023Parent}>
            <b
              className={styles.freshmanYear20212023}
              onClick={() => {
                handleOpenChat();
              }}
              style={{maxLines: 2}}
            >
              {chatTitle}
            </b>
            <Dropdown menu={{ items, onClick }}>
              <a onClick={(e) => e.preventDefault()}>
                <div className={styles.ellipsis1Wrapper}>
                  <img
                    className={styles.ellipsis1Icon}
                    alt=""
                    src="/ellipsis-11.svg"
                  />
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
        <div
          className={styles.resourceChipChild}
          onClick={() => {
            handleOpenChat();
          }}
        >
          <div className={styles.image9Parent}>
            <div className={styles.pages23DaysAgoParent}>
              <div className={styles.uploadedByJames}>
              {timeAgo + " • " + String(messageCount) + " Messages"}
              </div>
            </div>
          </div>
        </div>
      </button>
    );
  }
);

export default ChatChip;
