import { memo, useState, useMemo, useCallback } from "react";
import JoinClassModal from "./JoinClassModal";
import PortalPopup from "./PortalPopup";
import styles from "./StandardButton.module.css";
const StandardButton = memo(
  ({
    buttonText,
    backgroundColor,
    onClick,
    buttonWidth,
  }) => {
    const joinClassesStyle = useMemo(() => {
      return {
        backgroundColor: backgroundColor,
      };
    }, [backgroundColor]);

    const frameButtonStyle = useMemo(() => {
      return {
        backgroundColor: backgroundColor,
        background: backgroundColor,
        width: buttonWidth,
      };
    }, [
      backgroundColor,
      backgroundColor,
      buttonWidth,
    ]);

    return (
      <>
        <button
          className={styles.joinClassesWrapper}
          onClick={onClick}
          style={frameButtonStyle}
        >
          <b className={styles.joinClasses} style={joinClassesStyle}>
            {buttonText}
          </b>
        </button>
      </>
    );
  }
);

export default StandardButton;
