import { memo, useCallback } from "react";
import StandardButton from "./StandardButton";
import styles from "./HelpModal.module.css";
import { externalLinks } from '../backend/externalLinks'; // Adjust the path as needed


import Youtube from "../applicationIcons/modalityIcons/YouTube.png"

const HelpModal = memo(({ onClose }) => {

  const openSupportLink = useCallback(() => {
    window.open(externalLinks.contactSupport);
  }, []);

  const openDiscordLink = useCallback(() => {
    window.open(externalLinks.joinDiscord);
  }, []);

  return (
    <div className={styles.helpModal}>
      <div className={styles.helpModalvariant2}>
        <div className={styles.helpWrapper}>
          <b className={styles.help}>HELP</b>
        </div>
        <button className={styles.helpModalvariant2Inner}  onClick={() => window.open(externalLinks.whatIsBoilerGPTYoutube)}>
          <div className={styles.image13Parent}>
            <img className={styles.image13Icon} alt="" src={Youtube} />
            <div className={styles.whatIsBoilergptParent}>
              <b className={styles.whatIsBoilergpt}>What is BoilerGPT?</b>
              <div className={styles.aShortYoutube}>
                A short YouTube video explanation of how to use BoilerGPT.
              </div>
            </div>
          </div>
        </button>
        <button className={styles.helpModalvariant2Child} onClick={() => window.open(externalLinks.askBetterQuestionsYoutube)}>
          <div className={styles.image13Parent}>
            <img className={styles.image13Icon} alt="" src={Youtube} />
            <div className={styles.whatIsBoilergptParent}>
              <b className={styles.whatIsBoilergpt}>Ask Better Questions</b>
              <div className={styles.aShortYoutube}>
                Learn how to prompt BoilerGPT to get better answers.
              </div>
            </div>
          </div>
        </button>
        <div className={styles.whoMakesBoilergptGroup}>
          <b className={styles.whatToHelp}>Who makes BoilerGPT?</b>
          <div
            className={styles.theStudentsDo}
          >{`Purdue students do! BoilerGPT is a tool for students by students. We want to make learning easier for everyone!`}</div>
        </div>
        <div className={styles.whoMakesBoilergptGroup}>
          <b className={styles.whatToHelp}>Want to Help Out?</b>
          <div
            className={styles.theStudentsDo}
          >{`We need help from students of all disciplines to make AIs in your field even more powerful! Join our Discord to learn more.`}</div>
        </div>
        <StandardButton
          buttonText="Contact Support"
          backgroundColor="#efefef"
          buttonWidth="282px"
          onClick={openSupportLink}
        />
        <StandardButton
          buttonText="Join our Discord"
          backgroundColor="#50c7d7"
          buttonWidth="282px"
          onClick={openDiscordLink}
        />
      </div>
    </div>
  );
});

export default HelpModal;
