import React, { useContext, useState, useEffect } from "react"
import app from "../firebase"
import { getCurrentUserFirebase, getCurrentUserClassesFirebase } from "../backend/subscriptions"
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

const AuthContext = React.createContext()

export function useAuth() { return useContext(AuthContext) }

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged(user => {
            setLoading(true) 
            console.log("AUTH STATE CHANGED!")
            setCurrentUser(user)
            getCurrentUserFirebase()
            getCurrentUserClassesFirebase().then(() => {
                
                setLoading(false)   
            })
            
        })
        return unsubscribe
    }, [])

    
    if (loading) {
        return (
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh"  // This ensures the spinner takes the full viewport height
          }}>
            <Spinner color="#000000" size={20} speed={1.5} animating={true} />
      
            <div style={{ height: "10px" }}></div>
          </div>
        );
      }
      

    return (
        <AuthContext.Provider value={{currentUser}}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
