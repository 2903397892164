import { useState, memo } from "react";
import ClassChipSmall from "./ClassChipSmall";
import ClassChipLarge from "./ClassChipLarge";
import StandardButton from "./StandardButton";
import styles from "./CreateClassModal.module.css";
import { createClass } from "../backend/boilerGPT";
import { convertTimestamp } from "../backend/utility";
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import { useAuth } from "../contexts/FirebaseAuth"
import { useSelector } from 'react-redux';



const CreateClassModal = memo(({ onClose }) => {
  const { currentUser } = useAuth()
  const userData = useSelector((state) => state.userData);

  const [courseCode, setCourseCode] = useState("");
  const [classTopic, setClassTopic] = useState("");
  const [semester, setSemester] = useState("Fall 2023");

  const handleCourseCodeChange = (event) => {
    const input = event.target.value.toUpperCase(); // Convert to uppercase
    const formattedInput = input.replace(/[^A-Z0-9 ]/g, ""); // Remove special characters
    setCourseCode(formattedInput);
  };

  const handleClassTopicChange = (event) => {
    setClassTopic(event.target.value);
  };

  const handleSubmit = async () => {
    // Implement your logic here to handle form submission
    // You can access the updated values using the state variables (courseCode, classTopic, schoolName, semester)
    // For example:

    //get the school code from the user data
    const schoolName = userData.schoolName;
    const schoolCode = userData.schoolCode;

    console.log("Course Code:", courseCode);
    console.log("Class Topic:", classTopic);
    console.log("schoolName:", schoolName);
    console.log("Semester:", semester);

    await createClass(semester, courseCode, classTopic, schoolName, schoolCode);

    onClose();
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    // message.info('Click on menu item.');
    console.log('click', e);

    if(e.key == "1") {
      setSemester("Fall 2023");
    }
    else if(e.key == "2") {
      setSemester("Spring 2023");
    }
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        "Fall 2023"
      ),
    },
    {
      key: '2',
      label: (
        "Spring 2023"
      ),
    }
  ];

  return (
    <div className={styles.createClassModal}>
      <div className={styles.createClassModal1}>
        <div className={styles.createClassWrapper}>
          <b className={styles.createClass}>CREATE CLASS</b>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.courseCodeExMa265Parent}>
            <div className={styles.courseCodeEx}>Course Code (Ex. MA 265)</div>
            <input
              className={styles.frameChild}
              type="text"
              placeholder="CS 180"
              value={courseCode}
              onChange={handleCourseCodeChange}
              maxLength="10"
            />
          </div>
          <div className={styles.courseCodeExMa265Parent}>
            <div className={styles.courseCodeEx}>
              Class Topic (Ex. Linear Algebra)
            </div>
            <input
              className={styles.frameChild}
              type="text"
              placeholder="Linear Algebra"
              value={classTopic}
              onChange={handleClassTopicChange}
              //max length of 30 characters
              maxLength="32"
            />
          </div>
          {/* <div className={styles.courseCodeExMa265Parent}>
            <div className={styles.courseCodeEx}>University (Ex. Purdue)</div>
            <input
              className={styles.frameChild}
              type="text"
              placeholder="Harvard"
              value={university}
              onChange={handleUniversityChange}
            />
          </div> */}
          <div className={styles.courseCodeExMa265Parent}>
            <div className={styles.courseCodeEx}>
              Semester
            </div>
            {/* <input
              className={styles.frameChild}
              type="text"
              placeholder="Spring 2025"
              value={semester}
              onChange={handleSemesterChange}
            /> */}

          <Dropdown menu={{ items, onClick: handleMenuClick, }} placement="bottomLeft" arrow>
            <Button>{semester}</Button>
          </Dropdown>
          </div>
        </div>
        <div className={styles.createClassModalInner}>
          <img className={styles.lineIcon} alt="" src="/line-3.svg" />
        </div>
        <div className={styles.createClassWrapper}>
          <b className={styles.createClass}>CLASS PREVIEWS</b>
        </div>
        <ClassChipLarge
          courseCode={courseCode + " " + semester}
          courseTopic={classTopic} 
          courseInfo={"0 Students · " + convertTimestamp(Date.now()/1000)}
          getAnswersToAnyQuestionsYBorderRadius="9px"
          propWidth="168px"
          frame30AlignSelf="unset"
          frame30BackgroundColor="#efefef"
          frame30Width="282px"
          // image17="/image-171@2x.png"
        />
        <ClassChipSmall
          classTitle={courseCode + " " + semester}
          classSubject={classTopic}
          getAnswersToAnyQuestionsYBorderRadius="9px"
          propWidth="168px"
          frame30AlignSelf="unset"
          frame30BackgroundColor="#efefef"
          frame30Width="282px"
          // image17="/image-171@2x.png"
        />
        <div className={styles.createClassModalInner}>
          <img className={styles.lineIcon} alt="" src="/line-3.svg" />
        </div>
        <StandardButton
          buttonText="Create Class"
          backgroundColor="#50c7d7"
          buttonWidth="310px"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
});

export default CreateClassModal;
