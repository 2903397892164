export const externalLinks = {
    requestCourse: "https://forms.gle/NLY3WmoVjzrQHDoe7",
    requestModerator: "https://forms.gle/q5brqZKkhZYBqPDL8",
    resignModerator: "https://forms.gle/6vNwZa634F78w6jt8",
    helpUs: "https://docs.boilergpt.com/help-us-improve-boilergpt",
    contactSupport: "https://discord.gg/7f8MVb2bnM",
    joinDiscord: "https://discord.gg/7f8MVb2bnM",
    learnMore: "https://docs.boilergpt.com/asking-better-questions",
    langChain: "https://www.langchain.com/",
    newSchool: "https://docs.boilergpt.com/bring-ai-to-your-university",
    termsOfService: "https://docs.boilergpt.com/terms-of-service",

    introYoutube: "https://youtu.be/ArmJ5QNbVHY",
    whatIsBoilerGPTYoutube: "https://youtu.be/ArmJ5QNbVHY",
    askBetterQuestionsYoutube: "https://www.youtube.com/watch?v=9QZ0p7Yt1pM",

    //OLD
    linkResources: "https://docs.boilergpt.com/link-resources",
    uploadResources: "https://docs.boilergpt.com/upload-resources",
    GPTEZ: "https://gptez.com/",
};