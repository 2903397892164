import UserMessage from "../components/chat/UserMessage";
import LLMMessage from "../components/chat/LLMMessage";

import { toast } from "react-toastify";

import styles from "./LearnConcepts.module.css";
import { useNavigate, Navigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { generateResponse } from "../backend/boilerChain";
import { useAuth } from "../contexts/FirebaseAuth";
import { useSelector, useDispatch } from "react-redux";
import { externalLinks } from "../backend/externalLinks"; // Adjust the path as needed
import { setSelectedChatContent, setSelectedChatId } from "../redux/actions";
import {
  appendToLastMessage,
  appendResourcesToLastMessage,
} from "../redux/actions";

import store from "../redux/store";
import { createChat, updateChat } from "../backend/boilerGPT";
import ResourceChip from "../components/ResourceChip";

import { ago } from "../backend/utility";
import { BOILERCHAIN_URL } from "../backend/boilerGPT";

const ClassResources = () => {
  const { currentUser } = useAuth();
  const [userInput, setUserInput] = useState(""); // State for user input

  // const [selectedChatContent, setSelectedChatContent] = useState([]); // State for selected chat content
  const [streamingResponse, setStreamingResponse] = useState(false); // State for streaming response

  const userClassesData = useSelector((state) => state.userClassesData);
  const selectedClassIndex = useSelector((state) => state.selectedClassIndex);
  const selectedAIScholar = useSelector((state) => state.selectedAIScholar);
  const selectedChatContent = useSelector((state) => state.selectedChatContent);
  const selectedChatID = useSelector((state) => state.selectedChatID);

  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedChatContent]);

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const getResources = (query) => {
    try {
      const classID = userClassesData[selectedClassIndex]["classID"];
      const schoolCode = userClassesData[selectedClassIndex]["schoolCode"];
      const payload = { classID: classID, query: query, schoolCode: schoolCode };
      fetch(BOILERCHAIN_URL + "/getResources", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {

          //if "error" in data, then there was an error
          if ("error" in data) {
            toast.error("Error while getting resources:" + data["error"], { position: "bottom-right" });
            return;
          }

          console.log("data:", data);
          const resource_data = data["resource_data"];
          console.log(resource_data);
          store.dispatch(appendResourcesToLastMessage(resource_data));

          console.log("selectedChatContent:", selectedChatContent);
          // const LLMMessage = {
          //   role: "assistant",
          //   content: resources,
          // };
          // store.dispatch(
          //   setSelectedChatContent([...selectedChatContent, LLMMessage])
          // ); // Add user message to selected chat content
        });
    } catch (error) {
      console.error("Error occurred while getting resources:", error);
    }
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === "") {
      return;
    }

    const userMessage = {
      role: "user",
      content: userInput,
      resources: [],
    };

    const LLMMessage = {
      role: "assistant",
      content: "",
      resources: [],
    };

    store.dispatch(
      setSelectedChatContent([...selectedChatContent, userMessage, LLMMessage])
    ); // Add user message to selected chat content

    setUserInput(""); // Clear user input

    getResources(userInput);

    // // Call the API here to get LLM response and update selected chat content with the response
    await handleGenerateResponse([...selectedChatContent, userMessage]);

    //update our state from redux
    const chatContent = store.getState().selectedChatContent;
    //set a 5 second timeout to allow the user to read the response

    //If this is the first message, create a new chat
    if (chatContent.length == 2) {
      const response = await createChat(
        userClassesData[selectedClassIndex]["classID"],
        chatContent
      );

      console.log("response:", response);
      const chatID = response["data"]["chatID"];

      store.dispatch(setSelectedChatId(chatID));
    } else {
      console.log("updating chat");
      updateChat(selectedChatID, chatContent);
    }
  };

  const handleKeyPress = (event) => {
    var textarea = document.getElementById("mainchatentertextarea");
    textarea.style.height = "";
    textarea.style.height = Math.min(textarea.scrollHeight, 200) + "px";

    if (event.key === "Enter" && !event.shiftKey) {
      if (streamingResponse) {
        return;
      }
      event.preventDefault();
      handleSendMessage();
      var textarea = document.getElementById("mainchatentertextarea");
      textarea.style.height = "";
      textarea.style.height = "20px";
    } else {
      var textarea = document.getElementById("mainchatentertextarea");
      textarea.style.height = "";
      textarea.style.height =
        Math.max(Math.min(textarea.scrollHeight, 200), 25) + "px";
    }
  };

  const handleGenerateResponse = async (currentChatContent) => {
    try {
      setStreamingResponse(true);
      //remove the last AI message we just added from the messages argument
      const payload = {
        messages: currentChatContent,
        classID: userClassesData[selectedClassIndex]["classID"],
      };
      const response = await fetch(BOILERCHAIN_URL + "/generateResponse", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://platform.boilergpt.com",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      console.log("currentChatContent:", currentChatContent);

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setStreamingResponse(false);
          break;
        }

        // Massage and parse the chunk of data
        const chunk = decoder.decode(value, { stream: true });
        const lines = [chunk];

        const parsedLines = lines.map((line) => {
          const splitLines = line.split("data: ");
          const filteredLines = splitLines.filter(
            (line) => line !== "" && line !== "[DONE]"
          );

          const parsedJSON = filteredLines.map((line) => {
            return JSON.parse(line);
          });

          return parsedJSON;
        });

        //turn the array of arrays into a single array
        const flattenedParsedLines = [].concat.apply([], parsedLines);

        for (const parsedLine of flattenedParsedLines) {
          const { choices } = parsedLine;
          const { delta } = choices[0];
          const { content } = delta;
          // Update the UI with the new content
          if (content) {
            // Dispatch the action to append to the last message
            store.dispatch(appendToLastMessage(content));
          }
        }
      }
    } catch (error) {
      console.error("Error occurred while generating response:", error);
      toast.error("Error occurred while generating response: " + error, {
        position: "bottom-right",
      });
      setStreamingResponse(false);
    }
  };

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className={styles.frameParent4}>
        <div className={styles.frameParent5}>
          <>
            {selectedChatContent.map((message, index) => (
              <div key={index}>
                {message.role === "user" && (
                  <UserMessage text={message.content} />
                )}
                {message.role === "assistant" && (
                  <LLMMessage
                    text={message.content}
                    aiName={
                      selectedAIScholar === "pete"
                        ? "Professor Pete"
                        : userClassesData[selectedClassIndex]["classNumber"] +
                          " Master"
                    }
                    iconSrc={"/allergensfill-1.svg"}
                  />
                )}
                {message["resources"] != null &&
                  message["resources"].length > 0 && (
                    <div className={styles.frameWrapper2}>
                      <div className={styles.frameParent22}>
                        {message["resources"]
                          .slice(0, 3)
                          .map((data, resIndex) => (
                            <ResourceChip
                              key={resIndex}
                              resourceID={data.id}
                              title={data.resourceTitle}
                              uploadedBy={data.creatorName}
                              rawMediaURL={data.rawMediaURL}
                              timeAgo={ago(data.dateCreated)}
                              views={0}
                              modality={data.modality}
                              upvotes={0}
                              downvotes={0}
                              votable={true}
                              status={"Accepted"}
                              creatorID={data.creatorID}
                            />
                          ))}
                      </div>
                    </div>
                  )}
              </div>
            ))}
          </>
          <div ref={messagesEndRef}></div>
        </div>
        <div className={styles.frameParent9}>
          <div className={styles.frameParent10}>
            <textarea
              id="mainchatentertextarea"
              className={`${styles.frameChild} ${styles.autoResizeTextarea}`}
              placeholder="Ask questions here..."
              value={userInput}
              onChange={handleUserInput}
              onInput={handleUserInput}
              onKeyPress={handleKeyPress} // Add the key press event handler
              // rows={4} // Allow multiple lines in the input
              style={{ minHeight: "24px" }}
            ></textarea>
            <button
              className={styles.addResourceButton}
              onClick={handleSendMessage}
              // Disable the input if streamingResponse is true or userInput is empty
              disabled={streamingResponse || userInput.trim() === ""}
            >
              <img
                className={styles.paperplanefill1Icon}
                alt=""
                src="/paperplanefill-1.svg"
              />
            </button>
          </div>
          <div className={styles.chatWithResources}>
            Powered by&#160;
            <a href={externalLinks.langChain} target="_blank">
              LangChain.
            </a>
            {/* &#160;and&#160;
            <a href={externalLinks.GPTEZ} target="_blank">
              GPTEZ.
            </a> */}
            &#160;BoilerGPT may provide unfactual information.&#160;
            <a href={externalLinks.learnMore} target="_blank">
              <b>Learn more.</b>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassResources;
