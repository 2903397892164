import app from "../firebase";
import { setUserData, setUserClassesData, setSelectedClassModerators, setSelectedClassResources, setSelectedClassUserChatsData } from "../redux/actions";
import { leaveClass, getUser, requestModerator, getResource } from "./boilerGPT";
import store from "../redux/store";
// import firebase from 'firebase/app';
// import 'firebase/firestore';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firestore = app.firestore();

export const getCurrentUserFirebase = () => {
  try {
    const currentUser = app.auth().currentUser;
    if (currentUser) {
      const currentUserID = currentUser.uid;
      console.log(currentUserID);

      firestore
        .collection("Users")
        .where("userID", "==", currentUserID)
        .onSnapshot((snapshot) => {
          console.log("onSnapshot Called!");
          let updatedData = snapshot.docs.map((doc) => doc.data());
          console.log("rawUpdatedUserData", updatedData);
          console.log("updatedUserData", updatedData[0]);
          store.dispatch(setUserData(updatedData[0]));
          return updatedData[0];
        });
    } else {
      // User is not signed in
      console.log("User is not signed in");
      store.dispatch(setUserData([])); // Dispatch an action to clear or set user data to an empty array
    }
  } catch {
    console.log("Issue with redux and user auth");
    store.dispatch(setUserData([])); // Dispatch an action to clear or set user data to an empty array
  }
};

// export const getCurrentUserClassesFirebase = async () => {
//   try {
//     const currentUser = app.auth().currentUser;
//     if (currentUser) {
//       const currentUserID = currentUser.uid;

//       //Get the user data from firebase document
//       const userData = ( await firestore.collection("Users").where("userID", "==", currentUserID).get() ).docs[0].data(); // Get the user data from firebase document

//       const classIDs = [].concat(userData.classesTeaching).concat(userData.classesModerating).concat(userData.classesStudent);
//       console.log("classIDs", classIDs);
      

//       const classesCollection = firestore.collection("Classes");

//       // Create a query for the specified class IDs
//       const query = classesCollection.where("classID", "in", classIDs);

//       query.onSnapshot((snapshot) => {
//         console.log("onSnapshot Called!");

//         let updatedData = snapshot.docs.map((doc) => doc.data());
//         console.log("updatedClassesData", updatedData);
//         // Dispatch the action to update user classes data in the store
//         store.dispatch(setUserClassesData(updatedData));
//         return updatedData;
//       });

//     } else {
//       // User is not signed in
//       console.log("User is not signed in");
//       store.dispatch(setUserClassesData([])); // Dispatch an action to clear or set user classes data to an empty array
//     }
//   } catch (error) {
//     console.log("Error:", error); // Print the caught error
//     store.dispatch(setUserClassesData([])); // Dispatch an action to clear or set user classes data to an empty array
//   }
// };

export const getCurrentUserClassesFirebase = async () => {
  try {
    const currentUser = app.auth().currentUser;
    if (currentUser) {
      const currentUserID = currentUser.uid;

      // Get the user data from firebase document
      const userDataSnapshot = await firestore.collection("Users").where("userID", "==", currentUserID).get();
      const userData = userDataSnapshot.docs[0].data();

      const classIDs = [...userData.classesTeaching, ...userData.classesModerating, ...userData.classesStudent];
const classesCollection = firestore.collection("Classes");

// Split the classIDs array into chunks of 10 or fewer elements
const chunkSize = 10;
const chunks = [];
for (let i = 0; i < classIDs.length; i += chunkSize) {
  chunks.push(classIDs.slice(i, i + chunkSize));
}

// Create an array to store the promises for each query
const queryPromises = [];

// Perform a separate query for each chunk of classIDs
chunks.forEach(chunk => {
  const query = classesCollection.where("classID", "in", chunk);
  const promise = new Promise((resolve, reject) => {
    const unsubscribe = query.onSnapshot((snapshot) => {
      const updatedData = snapshot.docs.map((doc) => doc.data());
      unsubscribe();
      resolve(updatedData);
    }, (error) => {
      console.log("Error:", error);
      reject(error);
    });
  });
  queryPromises.push(promise);
});

// Use Promise.all to wait for all queries to complete
return Promise.all(queryPromises)
  .then(results => {
    // Merge the results from all queries into a single array
    const updatedData = [].concat(...results);
    store.dispatch(setUserClassesData(updatedData));
    return updatedData;
  })
  .catch(error => {
    console.error("Error:", error);
    store.dispatch(setUserClassesData([]));
    throw error;
  });

    } else {
      // User is not signed in
      console.log("User is not signed in");
      store.dispatch(setUserClassesData([]));
    }
  } catch (error) {
    console.log("Error:", error);
    store.dispatch(setUserClassesData([]));
  }
};



//--------------CLASS SPECIFIC FUNCTIONS-----------------//

export const getCurrentUserClassModerator = async (classData) => {
  //This method will get the moderators and teachers of a class
  if (classData === undefined) {
    return;
  }

  var moderatorsData = await Promise.all(
    classData.moderators.concat(classData.teachers).map((userID) => getUser(userID))
  );
  moderatorsData = moderatorsData.map((data) => data.data.data);
  console.log("MODER", moderatorsData)

  //Set the moderators data to the redux
  store.dispatch(setSelectedClassModerators(moderatorsData));
  return moderatorsData;
};

export const getCurrentUserClassResources = async (classData) => {
  if (classData === undefined) {
    return;
  }

  const firestore = firebase.firestore();
  const resourcesRef = firestore.collection('Resources');

  var querySnapshot = await resourcesRef.where('classID', '==', classData.classID).where('status', '!=', "Indexing Error").get( { source: 'cache' } );
  if (!querySnapshot.exists) {
    console.log('No cached data');
    querySnapshot = await resourcesRef.where('classID', '==', classData.classID).where('status', '!=', "Indexing Error").get();
  }
     

  const resourceData = querySnapshot.docs.map((doc) => doc.data());

  console.log('RESOURCES', resourceData);

  store.dispatch(setSelectedClassResources(resourceData));
  return resourceData;
}

export const getCurrentUserClassChats = async (classData) => {
  const currentUser = app.auth().currentUser;
    if (currentUser) {
      const currentUserID = currentUser.uid;

      const firestore = firebase.firestore();
      const chatsRef = firestore.collection('Chats');
       
      var querySnapshot = await chatsRef.where('classID', '==', classData.classID).where('creatorID', '==', currentUserID).get( { source: 'cache' } );
      if (!querySnapshot.exists) {
        console.log('No cached data');
        querySnapshot = await chatsRef.where('classID', '==', classData.classID).where('creatorID', '==', currentUserID).get();
      }

      const chatsData = querySnapshot.docs.map((doc) => doc.data());

      console.log('CHATS', chatsData);

      store.dispatch(setSelectedClassUserChatsData(chatsData));
      return chatsData;
    } else {
      // User is not signed in
      console.log("User is not signed in");
      store.dispatch(setSelectedClassUserChatsData([]));
    }
}
