// import {
//   Routes,
//   Route,
//   useNavigationType,
//   useLocation,
// } from "react-router-dom";
import ReactGA from "react-ga";
import SignInScreen from "./pages/SignInScreen";
import { useEffect } from "react";
import { AuthProvider } from "./contexts/FirebaseAuth";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import ClassResources from "./pages/ClassResources";
import TopicResources from "./pages/TopicResources";
import AddResources from "./pages/AddResources";
import PracticeProblems from "./pages/PracticeProblems";
import LearnConcepts from "./pages/LearnConcepts";
import OnboardingScreen from "./pages/OnboardingScreen";
import Resource from "./pages/Resource";
import styles from "./pages/ClassResources.module.css";
import { Provider } from "react-redux";
import { PythonProvider } from "react-py";
import React from "react";
import { Spinner } from "react-bootstrap";

import store from "./redux/store";
import {
  getCurrentUserFirebase,
  getCurrentUserClassesFirebase,
} from "./backend/subscriptions";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigationType,
  useLocation,
} from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;
const GA_DEBUG_MODE = process.env.REACT_APP_GA_DEBUG_MODE === "true";
ReactGA.initialize(GA_TRACKER_ID, { debug: GA_DEBUG_MODE });

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  ReactGA.pageview(window.location.href);

  //lets only debug if we are in localhost
  var DEBUG = window.location.href.indexOf("localhost") > -1;
  if(!DEBUG){
      if(!window.console) window.console = {};
      var methods = ["log", "debug", "warn", "info"];
      for(var i=0;i<methods.length;i++){
          console[methods[i]] = function(){};
      }
  }

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  const routes = [
    {
      path: "/",
      element: <SignInScreen />,
      renderHeader: false,
      renderSidebar: false,
      renderChatSelector: false,
    },
    {
      path: "/onboarding",
      element: <OnboardingScreen />,
      renderHeader: false,
      renderSidebar: false,
      renderChatSelector: false,
    },
    {
      path: "/resource",
      element: <Resource />,
      renderHeader: false,
      renderSidebar: false,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/class-resources",
      element: <ClassResources />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/topic-resources",
      element: <TopicResources />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/learn",
      element: <LearnConcepts />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/consensus",
      element: <ClassResources />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/add",
      element: <AddResources />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/practice",
      element: <PracticeProblems />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: false,
    },
    {
      path: "/dashboard/assignments",
      element: <ClassResources />,
      renderHeader: true,
      renderSidebar: true,
      renderChatSelector: true,
    },
  ];

  const packages = {
    official: ['pyodide-http', 'scipy', 'sympy'],
    micropip: [],
  }

  return (
    <AuthProvider>
      <Provider store={store}>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <>
                  <div className={styles.classResources}>
                    {route.renderSidebar && <Sidebar />}
                    <div className={styles.headerParent}>
                      {route.renderHeader && <Header renderChatSelector={route.renderChatSelector} />}
                      <PythonProvider packages={packages}>
                        {route.element}
                      </PythonProvider>
                      <ToastContainer />
                    </div>
                  </div>
                </>
              }
            />
          ))}
        </Routes>
      </Provider>
    </AuthProvider>
  );
}

export default App;
